import { defaultEmailHtmlContent, defaultPDFHtmlContent } from './defaultHtmlContentData';

export const allTemplateTypes: {
  [key: string]: { key: string; name: string; defaultHtml?: string | undefined };
} = {
  email: {
    key: 'email',
    name: 'Email',
    defaultHtml: defaultEmailHtmlContent,
  },
  pdf: {
    key: 'pdf',
    name: 'Pdf',
    defaultHtml: defaultPDFHtmlContent,
  },
  supista: {
    key: 'supista',
    name: 'Supista',
  },
  custom: {
    key: 'custom',
    name: 'Custom',
  },

  doc: {
    key: 'doc',
    name: 'Doc',
  },
  xlsx: {
    key: 'xlsx',
    name: 'Xlsx',
  },
};
export const templateTypeObject = {
  email: allTemplateTypes.email,
  pdf: allTemplateTypes.pdf,
};
export const defaultTemplateCode = `function customizeERP({token, functionID, appID, userData},{readOp}) {
    // Your Code Starts Here

}`;
// export const defaultTemplateCode = `function validateData(data) {
//     return data;
// }

// function validateSchema(data) {
//     return data;
// }

// function changeData (data) {
//     return data;
// }

// /**
//  * @param {data}: { errors, formData, formSchema }
//  */
// function customizeERP({token, functionID, appID, userData},{readOp}) {
//     const { __d3__formData, __d3__error, __d3__schema } = data
//     // Your Code Starts Here
//     let newData;
//     newData = validateData(data);
//     newData = validateSchema(data);
//     newData = changeData (data);

//     return newData;
// }`;
