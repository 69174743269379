import React, { useState } from 'react';
import { Form } from 'react-bootstrap';
import styles from './expandingTextarea.module.scss';

interface expandingTextareaProps {
  value: any;
  placeholder: string;
  name: string;
  onChange: Function;
  maxLineCount?: number;
}
const ExpandingTextarea = ({
  value,
  placeholder,
  name,
  onChange,
  maxLineCount = 3,
}: expandingTextareaProps) => {
  const [rows, setRows] = useState(1);

  const handleInputChange = (e: any) => {
    const lineCount = e.target.value.split('\n').length;
    setRows(Math.min(maxLineCount, lineCount)); // Limit to 3 rows
    onChange(e);
  };

  return (
    <Form.Control
      value={value}
      name={name}
      placeholder={placeholder}
      as='textarea'
      className={styles.textareaScrollable}
      rows={rows}
      onChange={handleInputChange}
      // style={{ resize: 'none', overflow: 'hidden' }}
      style={{
        resize: 'none',
        overflowY: 'auto',
        maxHeight: `${maxLineCount * 1.5}em`, // Adjust the multiplier based on line height
      }}
    />
  );
};

export default ExpandingTextarea;
