export const timeIntervalFieldsType = {
  number: 'number',
  inputString: 'inputString',
  timeField: 'timeField',
  timeDropDown: 'timeDropDown',
  weekField: 'weekField',
  monthField: 'monthField',
};
export const timeIntervalWeekOption = {
  1: {
    name: 'M',
    key: 1,
    lable: 'Monday',
  },
  2: {
    name: 'Tu',
    key: 2,
    label: 'Tuesday',
  },
  3: {
    name: 'W',
    key: 3,
    label: 'Wednesday',
  },
  4: {
    name: 'Th',
    key: 4,
    label: 'Thursday',
  },
  5: {
    name: 'F',
    key: 5,
    label: 'Friday',
  },
  6: {
    name: 'S',
    key: 6,
    label: 'Saturday',
  },
  7: {
    name: 'Su',
    key: 7,
    label: 'Sunday',
  },
};
export const timeIntervalType = {
  // hour: {
  //   key: 'hour',
  //   title: 'Hourly',
  //   timeIntervalOptions: [
  //     {
  //       name: 'interval',
  //       label: 'Hours Interval',
  //       required: true,
  //       defaultValue: 3,
  //       minValue: 1,
  //       maxValue: 24,
  //       type: timeIntervalFieldsType.number,
  //     },
  //   ],
  // },
  day: {
    key: 'day',
    title: 'Every Day',
    timeIntervalOptions: [
      // {
      //   name: 'interval',
      //   label: 'Days Interval',
      //   required: true,
      //   defaultValue: 1,
      //   minValue: 1,
      //   maxValue: 28,
      //   type: timeIntervalFieldsType.number,
      // },
      {
        name: 'trigger_at_hour',
        label: 'Trigger At Hour',
        required: true,
        defaultValue: [0],
        type: timeIntervalFieldsType.timeField,
      },
    ],
  },
  week_days: {
    key: 'week_days',
    title: 'Week Days',
    timeIntervalOptions: [
      {
        name: 'list_of_intervals',
        label: 'Week Interval',
        required: true,
        defaultValue: [timeIntervalWeekOption[1].key],
        type: timeIntervalFieldsType.weekField,
      },
      // {
      //   name: 'trigger_at_hour',
      //   label: 'Trigger At Hour',
      //   required: true,
      //   defaultValue: [0],
      //   type: timeIntervalFieldsType.timeField,
      // },
      {
        name: 'trigger_at_hour',
        label: 'Trigger At Hour',
        required: true,
        defaultValue: [0],
        type: timeIntervalFieldsType.timeDropDown,
      },
    ],
  },
  day_of_month: {
    key: 'day_of_month',
    title: 'Day of month',
    timeIntervalOptions: [
      {
        name: 'list_of_intervals',
        label: 'Month Interval',
        required: true,
        defaultValue: [1],
        type: timeIntervalFieldsType.monthField,
      },
      // {
      //   name: 'trigger_at_hour',
      //   label: 'Trigger At Hour',
      //   required: true,
      //   defaultValue: [0],
      //   type: timeIntervalFieldsType.timeField,
      // },
      {
        name: 'trigger_at_hour',
        label: 'Trigger At Hour',
        required: true,
        defaultValue: [0],
        type: timeIntervalFieldsType.timeDropDown,
      },
    ],
  },
  // hour_list: {
  //   key: 'hour_list',
  //   title: 'Hour List',
  //   timeIntervalOptions: [
  //     {
  //       name: 'list_of_intervals',
  //       label: 'Hour Interval',
  //       required: true,
  //       defaultValue: 3,
  //       minValue: 1,
  //       maxValue: 24,
  //       type: timeIntervalFieldsType.number,
  //     },
  //   ],
  // },
};
let randomNumber = Math.floor(Math.random() * 24);

export const defaultTimeInterval = {
  intervalType: timeIntervalType.week_days.key,
  list_of_intervals: [timeIntervalWeekOption[7].key],
  trigger_at_hour: [randomNumber],
};
