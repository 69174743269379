import React, { useState } from 'react';
import styles from './templatesWrapper.module.scss';
import { mls } from 'lib/multilanguagesupport';
import { MdOutlineArrowBackIos } from 'react-icons/md';
import { CircularProgress } from '@mui/material';
import { templateTypeObject } from './templateData';
import TableSchemaSelection from '../CodeCustomization/AICustomizationEditorContainer/TableSchemaSelection';
import { Row, Col, Form } from 'react-bootstrap';

interface createTemplateHeaderProps {
  setIsTemplateForm: Function;
  downloadPdfButtonRef: any;
  isTemplateForm: boolean;
  isNewTemplate: boolean;
  setIsTemplateSetting: Function;
  templateData: any;
  selectedTemplateID: string;
  componentSchema: any;
  setComponentSchema: Function;
  setTemplateData: Function;
  appDatatable: any;
  connectedTablesData: any;
  componentRowList: any;
  selectedId: any;
  handleComponentChange: Function;
}
const CreateTemplateHeader = ({
  setIsTemplateForm,
  downloadPdfButtonRef,
  isTemplateForm,
  isNewTemplate,
  setIsTemplateSetting,
  templateData,
  appDatatable,
  connectedTablesData,
  selectedTemplateID,
  componentSchema,
  setComponentSchema,
  setTemplateData,
  componentRowList,
  selectedId,
  handleComponentChange,
}: createTemplateHeaderProps) => {
  const [isPdfDownloading, setIsPdfDownloading] = useState(false);
  const handleBackButton = () => {
    if (!isTemplateForm) {
      setIsTemplateForm(true);
    } else {
      setIsTemplateSetting(false);
    }
  };
  const downloadPdf = async () => {
    if (downloadPdfButtonRef?.current?.downloadPdf) {
      setIsPdfDownloading(true);
      const dowloadingRes = await downloadPdfButtonRef?.current?.downloadPdf();
      if (!dowloadingRes.skip) {
        setIsPdfDownloading(false);
      }
    }
  };
  const handleSave = () => {
    if (selectedTemplateID) {
      setComponentSchema({
        ...componentSchema,
        templates: {
          ...(componentSchema?.templates ?? {}),
          [selectedTemplateID]: templateData,
        },
      });
    }
    setIsTemplateSetting(false);
    setIsTemplateForm(false);
  };
  const handleDelete = () => {
    if (selectedTemplateID) {
      let finalEmailTemplate: { [key: string]: any } = {
        ...(componentSchema?.templates ?? {}),
      };

      delete finalEmailTemplate[selectedTemplateID];
      setComponentSchema({
        ...componentSchema,
        templates: finalEmailTemplate,
      });
    }
    setIsTemplateSetting(false);
    setIsTemplateForm(false);
  };
  const [isTableSchema, setIsTableSchema] = useState(false);
  const updateTableSchemaFunction = (tableSchema: any) => {
    setTemplateData({
      ...templateData,
      tableSchema,
    });
  };

  return (
    <div className={styles.createTemplateHeader}>
      <div className={styles.backButton} onClick={() => handleBackButton()}>
        <MdOutlineArrowBackIos />
      </div>
      <div className={styles.buttonContainer}>
        {!isTemplateForm ? (
          <>
            <div style={{ width: 'auto', display: 'flex', textAlign: 'end' }}>
              <Form.Group as={Row} className='' style={{ marginLeft: 0, marginRight: 0 }}>
                <Form.Label column sm={3}>
                  {mls('Id')}:
                </Form.Label>
                <Col sm={9}>
                  <Form.Control
                    as='select'
                    name='selectId'
                    value={selectedId}
                    style={{ textAlign: 'end' }}
                    onChange={(e) => handleComponentChange(e)}
                  >
                    <option value='' disabled>
                      {mls('Select Id')}
                    </option>
                    {React.Children.toArray(
                      (componentRowList ?? []).map((componentRowData: any) => {
                        return (
                          <option value={componentRowData?.id ?? ''} disabled={false}>
                            {componentRowData?.id ?? ''}
                          </option>
                        );
                      })
                    )}
                  </Form.Control>
                </Col>
              </Form.Group>
            </div>
          </>
        ) : null}
        {!isTemplateForm && !isNewTemplate ? (
          <>
            <button
              type='button'
              className='primaryButton secondaryColor'
              onClick={() => handleDelete()}
            >
              {/* {isPdfDownloading ? (
                <>
                  <CircularProgress size={10} color='inherit' style={{ color: 'white' }} /> &nbsp;
                </>
              ) : null} */}
              {mls('Delete')}
            </button>
          </>
        ) : null}
        {!isTemplateForm ? (
          <>
            <button type='button' className='primaryButton' onClick={() => handleSave()}>
              {/* {isPdfDownloading ? (
                <>
                  <CircularProgress size={10} color='inherit' style={{ color: 'white' }} /> &nbsp;
                </>
              ) : null} */}
              {mls('Save')}
            </button>
            <div
              style={{
                width: 'auto',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                // gap: '1rem',
              }}
            >
              <button
                className='primaryButton secondaryButtonColor'
                onClick={() => setIsTableSchema(true)}
              >
                {mls('Table Schema')}
              </button>
              <div>
                {isTableSchema ? (
                  <TableSchemaSelection
                    isTableSchema={isTableSchema}
                    appDatatable={appDatatable}
                    setIsTableSchema={setIsTableSchema}
                    connectedTablesData={connectedTablesData}
                    componentSchema={componentSchema}
                    updateTableSchemaFunction={updateTableSchemaFunction}
                    selectedData={templateData}
                    selectedCustomizationTable={templateData?.origin}
                  />
                ) : null}
              </div>
            </div>
          </>
        ) : null}

        {!isTemplateForm && templateTypeObject.pdf.key === templateData.type ? (
          <>
            <button
              type='button'
              className='primaryButton secondaryButtonColor'
              onClick={() => downloadPdf()}
            >
              {isPdfDownloading ? (
                <>
                  <CircularProgress size={10} color='inherit' /> &nbsp;
                </>
              ) : null}
              {mls('Download PDF')}
            </button>
          </>
        ) : null}
      </div>
    </div>
  );
};
export default CreateTemplateHeader;
