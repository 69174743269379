import React, { useEffect, useRef } from 'react';
import Editor from '@monaco-editor/react';

interface codeEditorProps {
  height?: string | number | undefined;
  value: string;
  defaultLanguage: string;
  onChange: (value: string | undefined) => void;
  autoFormatTriggerCharacters?: any;
  readOnly?: boolean;
  theme?: string;
}
const CodeEditor = ({
  height = '100%',
  value,
  defaultLanguage = 'json',
  onChange,
  autoFormatTriggerCharacters,
  readOnly = false,
  theme = 'vs-dark',
}: codeEditorProps) => {
  const monacoRef = useRef<any>(null);
  const codeFormatter = () => {
    if (monacoRef.current) {
      if (readOnly) {
        monacoRef.current.updateOptions({ readOnly: false });
      }
      monacoRef.current.setValue(value);
      monacoRef.current.getAction('editor.action.formatDocument').run();
      if (readOnly) {
        setTimeout(() => {
          monacoRef.current.updateOptions({ readOnly: true });
        }, 100);
      }
    }
  };
  const handleEditorDidMount = (editor: any) => {
    monacoRef.current = editor;
    // Configure formatting options
    editor.updateOptions({
      tabSize: 2, // Set the number of spaces for tabs
      insertSpaces: true, // Use spaces instead of tabs
      autoIndent: 'full', // Enable full auto-indentation
    });
    codeFormatter();
  };
  useEffect(() => {
    codeFormatter();
    // if (monacoRef?.current) {
    //   monacoRef?.current.updateOptions({ readOnly: false });
    //   monacoRef?.current.setValue(value);
    //   monacoRef?.current.getAction('editor.action.formatDocument').run();
    //   setTimeout(() => {
    //     monacoRef?.current.updateOptions({ readOnly: true });
    //   }, 3000);
    // }
  }, [autoFormatTriggerCharacters]);

  return (
    <>
      <Editor
        height={height}
        defaultLanguage={defaultLanguage}
        value={value}
        onChange={onChange}
        options={{
          tabSize: 2,
          automaticLayout: true,
          formatOnPaste: true,
          formatOnType: true,
          // readOnly: true,
        }}
        onMount={handleEditorDidMount}
        theme={theme}
      />
    </>
  );
};

export default CodeEditor;
