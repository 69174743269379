import React, { useMemo, useState } from 'react';
import { Button, Form, Row, Col } from 'react-bootstrap';
import { XCircleFill } from 'react-bootstrap-icons'; // Importing the icon from react-bootstrap-icons
import Select from 'react-select';
import { relationTypeArrayOptions, relationTypesObject } from './data/erpData';
import { generateShortUUID, getIDColumnName } from './util/utilFunctions';
import { mls } from 'lib/multilanguagesupport';
import { toast } from 'react-toastify';
import { updateRelatedTableSchema } from '../modal/add-database/helper/updateRelatedTableSchemaTs';

const RelationsComponent = ({
  allDataTableData,
  dataTableSchema,
  setDataTableSchema,
  setRelatedTableList,
  setTableUpdates,
  tableUpdates,
  relatedTableList,
}) => {
  const appID = dataTableSchema.appID;
  const [relations, setRelations] = useState(dataTableSchema?.relations || {});
  const [newRelations, setNewRelations] = useState({
    type: '',
    target: '',
  });

  const [errors, setErrors] = useState('');
  // console.log('dataTableSchema', dataTableSchema);
  // console.log('relations', relations);
  // console.log('relatedTableList', relatedTableList);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    // let displayName = newRelations.displayName;
    let relationName = newRelations.relationName;
    if (name === 'target') {
      relationName = `${value} ID`;
    }
    if (name === 'relationName') {
      if (value.length > 30) {
        let formErrors = { ...errors };
        formErrors.relationName = mls('Max 30 characters allowed for Relation Name.');
        setErrors(formErrors.relationName);
        return;
      }
      relationName = `${value} ID`;
    }

    setNewRelations({
      ...newRelations,
      relationName: relationName,
      [name]: value,
    });

    // Clear the error on input change
    setErrors('');
  };

  const validateForm = () => {
    let formIsValid = true;
    let errorMessage = '';

    if (newRelations.type === '' && newRelations.target === '') {
      formIsValid = false;
      errorMessage = 'Relation Type and Relation Target are required.';
    } else if (newRelations.type === '') {
      formIsValid = false;
      formIsValid = false;
      errorMessage = 'Relation Type is required.';
    } else if (newRelations.target === '') {
      formIsValid = false;
      errorMessage = 'Target Table is required.';
    } else if (newRelations.relationName === '') {
      formIsValid = false;
      errorMessage = 'Relation Name is required.';
    }
    if (newRelations.type === relationTypesObject.belongsTo.value) {
      let columnSchema = dataTableSchema?.columnSchema ?? [];
      const columnName = newRelations?.relationName;
      const isColumnPresent = columnSchema.some((col) => col?.columnName === columnName);
      if (isColumnPresent) {
        formIsValid = false;
        // errorMessage = 'This combination of Relation Name and Target Table already exists.';
        errorMessage = 'Belongs To relation cannot be added because columnName already exists.';
      }
    }
    if (
      newRelations.type === relationTypesObject.hasMany.value ||
      newRelations.type === relationTypesObject.hasOne.value
    ) {
      const targetTableName = newRelations.target;
      let targetTableSchema = {
        ...allDataTableData[targetTableName],
      };

      let columnSchema = targetTableSchema?.columnSchema ?? [];
      const columnName = newRelations?.relationName;
      const isColumnPresent = columnSchema.some((col) => col?.columnName === columnName);
      if (isColumnPresent) {
        formIsValid = false;
        // errorMessage = 'This combination of Relation Name and Target Table already exists.';
        errorMessage = 'Belongs To relation cannot be added because columnName already exists.';
      }
    }
    // Check if the same combination of relation type and target table already exists
    // const isDuplicate = Object.values(relations || []).some(
    //   (relation) => relation.type === newRelations.type && relation.target === newRelations.target
    // );
    // if (isDuplicate) {
    //   formIsValid = false;
    //   errorMessage = 'This combination of Relation Type and Target Table already exists.';
    // }

    if (
      newRelations.type === relationTypesObject.hasMany.value ||
      newRelations.type === relationTypesObject.hasOne.value
    ) {
      const createdColumnName = getIDColumnName(
        dataTableSchema.tableID,
        newRelations?.relationName
      );
      const targetTableName = newRelations.target;
      let targetTableSchema = {
        ...(relatedTableList[targetTableName] || allDataTableData[targetTableName]),
      };
      const isColumnPresent = (targetTableSchema?.columnSchema || []).some(
        (obj) => obj.columnName === createdColumnName
      );
      if (isColumnPresent) {
        formIsValid = false;
        errorMessage = 'This combination of Relation Name and Target Table already exists.';
      }
    }
    if (newRelations.type === relationTypesObject.belongsTo.value) {
      const createdColumnName = getIDColumnName(newRelations.target, newRelations?.relationName);
      const targetTableName = dataTableSchema.tableID;
      let targetTableSchema = {
        ...(dataTableSchema || allDataTableData[targetTableName]),
      };
      const isColumnPresent = (targetTableSchema?.columnSchema || []).some(
        (obj) => obj.columnName === createdColumnName
      );
      if (isColumnPresent) {
        formIsValid = false;
        errorMessage = 'This combination of Relation Name and Target Table already exists.';
      }
    }

    setErrors(errorMessage);
    return formIsValid;
  };

  const udpateBelongsTo = ({ relationData, action, relationElem, relationId }) => {
    let columnSchema = dataTableSchema?.columnSchema ?? [];

    if (relationElem.type === relationTypesObject.belongsTo.value) {
      if (action === 'add') {
        // const columnName = getIDColumnName(relationElem.target, newRelations?.relationName);
        const columnName = newRelations?.relationName;
        const isColumnPresent = columnSchema.some((col) => col?.columnName === columnName);
        if (!isColumnPresent) {
          const newColumnSchema = {
            // name: `${relationElem.target} ID` ?? `${columnName} ID`,
            // name: `${columnName}`,
            name: `${newRelations?.relationName}`,
            columnName: columnName,
            dataType: relationTypesObject.belongsTo.value,
            relationId: relationId,
          };
          columnSchema = [...columnSchema, newColumnSchema];
          const updatedAddColumn = [...(tableUpdates?.addColumns ?? []), newColumnSchema];
          setTableUpdates({
            ...(tableUpdates ?? {}),
            addColumns: updatedAddColumn,
          });
        } else {
          toast.error(mls('Belongs To relation cannot be added because it is already present.'));
        }
      }
      if (action === 'remove') {
        const columnName = getIDColumnName(relationElem.target, relationElem?.relationName);
        let isAddColumnDelete = false;
        let isNormalColumnDelete = false;
        columnSchema = columnSchema.filter((col) => {
          const isNotFound = col?.columnName !== columnName;
          if (!isNotFound) {
            isNormalColumnDelete = true;
          }
          return isNotFound;
        });

        const updatedAddColumn = (tableUpdates?.addColumns ?? []).filter((col) => {
          const isNotFound = col?.columnName !== columnName;
          if (!isNotFound) {
            isAddColumnDelete = true;
          }
          return isNotFound;
        });

        let updatedDeleteColumns = tableUpdates?.deleteColumns ?? [];
        if (!isAddColumnDelete && isNormalColumnDelete) {
          updatedDeleteColumns = [...updatedDeleteColumns, relationElem];
        }
        setTableUpdates({
          ...(tableUpdates ?? {}),
          addColumns: updatedAddColumn,
          deleteColumns: updatedDeleteColumns,
        });
      }
    }
    if (
      relationElem?.type === relationTypesObject.hasMany.value ||
      relationElem?.type === relationTypesObject.hasOne.value
    ) {
      if (relationElem?.target === dataTableSchema?.tableID) {
        return;
      }
    }
    const updatedDataTable = {
      ...dataTableSchema,
      columnSchema: columnSchema,
      relations: relationData,
    };
    setDataTableSchema(updatedDataTable);
  };
  const handleAddRelation = () => {
    if (!validateForm()) {
      return;
    }
    // const uuid = uuidv4();
    let uuid = generateShortUUID({});
    if (uuid in relations) {
      uuid = generateShortUUID({});
    }

    let foreignKey;
    if (
      newRelations.type === relationTypesObject.hasMany.value ||
      newRelations.type === relationTypesObject.hasOne.value
    ) {
      foreignKey = getIDColumnName(newRelations.target, newRelations?.relationName);
    } else if (newRelations.type === relationTypesObject.belongsTo.value) {
      foreignKey = getIDColumnName(dataTableSchema.target, newRelations?.relationName);
    } else if (newRelations.type === relationTypesObject.belongsToMany.value) {
      foreignKey = getIDColumnName(dataTableSchema.name);
    }
    let relationData;
    relationData = {
      ...newRelations,
      as: uuid,
      foreignKey,
      // foreignKey: newRelations.type === relationTypesObject.belongsTo.value ? 'id' : foreignKey,
    };

    if (newRelations.type === relationTypesObject.belongsToMany.value) {
      // const uuid = uuidv4();
      const uuid = generateShortUUID({});

      // relationData.through = `${dataTableSchema.name}-${newRelations.target}-${newRelations?.relationName}-throughTable`;
      relationData.through = uuid;
      relationData.otherKey = getIDColumnName(newRelations.target);
      // relationData.otherKey = newRelations?.target;
    }
    let updatedRelationData = relations;
    if (
      newRelations.type === relationTypesObject.hasMany.value ||
      newRelations.type === relationTypesObject.hasOne.value
    ) {
      updatedRelationData = {
        ...relations,
        [uuid]: {
          ...relationData,
          foreignKey: 'id',
        },
      };
    } else if (newRelations.type === relationTypesObject.belongsTo.value) {
      updatedRelationData = {
        ...relations,
        [uuid]: {
          ...relationData,
          // foreignKey: getIDColumnName(newRelations.target, newRelations?.relationName),
          foreignKey: newRelations?.relationName,
        },
      };
    } else if (newRelations.type === relationTypesObject.belongsToMany.value) {
      updatedRelationData = {
        ...relations,
        [uuid]: {
          ...relationData,
          // foreignKey: getIDColumnName(newRelations.target),
          foreignKey: getIDColumnName(dataTableSchema?.tableID),
          // foreignKey: getIDColumnName(newRelations.target, newRelations?.relationName),
        },
      };
    }
    setRelations(updatedRelationData);
    // Update target table schema with the new relation
    updateRelatedTableSchema({
      allDataTableData,
      appID,
      mainTableName: dataTableSchema.name,
      tableUpdates,
      setTableUpdates,
      setRelatedTableList,
      relatedTableList,
      relationData: relationData,
      action: 'add',
      dataTableSchema,
      updatedRelationData,
      setRelations,
      setDataTableSchema,
    });
    udpateBelongsTo({
      relationData: updatedRelationData,
      action: 'add',
      // relationElem: newRelations,
      relationElem: newRelations,
      relationId: uuid,
    });
    setNewRelations({
      type: '',
      target: '',
      // displayName: '',
      relationName: '',
    });
  };
  const handleAddRelationOld = () => {
    if (!validateForm()) {
      return;
    }
    // const uuid = uuidv4();
    let uuid = generateShortUUID({});
    if (uuid in relations) {
      uuid = generateShortUUID({});
    }

    let foreignKey;
    if (
      newRelations.type === relationTypesObject.hasMany.value ||
      newRelations.type === relationTypesObject.hasOne.value
    ) {
      foreignKey = getIDColumnName(newRelations.target, newRelations?.relationName);
    } else if (newRelations.type === relationTypesObject.belongsTo.value) {
      foreignKey = getIDColumnName(dataTableSchema.target, newRelations?.relationName);
    } else if (newRelations.type === relationTypesObject.belongsToMany.value) {
      foreignKey = getIDColumnName(dataTableSchema.name);
    }
    let relationData;
    relationData = {
      ...newRelations,
      as: uuid,
      foreignKey,
      // foreignKey: newRelations.type === relationTypesObject.belongsTo.value ? 'id' : foreignKey,
    };

    if (newRelations.type === relationTypesObject.belongsToMany.value) {
      // const uuid = uuidv4();
      const uuid = generateShortUUID({});

      // relationData.through = `${dataTableSchema.name}-${newRelations.target}-${newRelations?.relationName}-throughTable`;
      relationData.through = uuid;
      relationData.otherKey = getIDColumnName(newRelations.target);
      // relationData.otherKey = newRelations?.target;
    }
    let updatedRelationData = relations;
    if (
      newRelations.type === relationTypesObject.hasMany.value ||
      newRelations.type === relationTypesObject.hasOne.value
    ) {
      updatedRelationData = {
        ...relations,
        [uuid]: {
          ...relationData,
          foreignKey: 'id',
        },
      };
    } else if (newRelations.type === relationTypesObject.belongsTo.value) {
      updatedRelationData = {
        ...relations,
        [uuid]: {
          ...relationData,
          // foreignKey: getIDColumnName(newRelations.target, newRelations?.relationName),
          foreignKey: newRelations?.relationName,
        },
      };
    } else if (newRelations.type === relationTypesObject.belongsToMany.value) {
      updatedRelationData = {
        ...relations,
        [uuid]: {
          ...relationData,
          // foreignKey: getIDColumnName(newRelations.target),
          foreignKey: getIDColumnName(dataTableSchema?.tableID),
          // foreignKey: getIDColumnName(newRelations.target, newRelations?.relationName),
        },
      };
    }
    setRelations(updatedRelationData);
    // Update target table schema with the new relation
    updateRelatedTableSchema({
      allDataTableData,
      appID,
      mainTableName: dataTableSchema.name,
      tableUpdates,
      setTableUpdates,
      setRelatedTableList,
      relationData: relationData,
      action: 'add',
      dataTableSchema,
      updatedRelationData,
      setRelations,
      setDataTableSchema,
    });
    udpateBelongsTo({
      relationData: updatedRelationData,
      action: 'add',
      // relationElem: newRelations,
      relationElem: newRelations,
      relationId: uuid,
    });
    setNewRelations({
      type: '',
      target: '',
      // displayName: '',
      relationName: '',
    });
  };
  const handleRemoveRelation = (key) => {
    const relationToRemove = relations[key];

    const updatedRelations = { ...relations };
    delete updatedRelations[key];
    setRelations(updatedRelations);

    // Update target table schema by removing the relation
    updateRelatedTableSchema({
      allDataTableData,
      appID,
      mainTableName: dataTableSchema.name,
      tableUpdates,
      setTableUpdates,
      setRelatedTableList,
      relationData: relationToRemove,
      action: 'remove',
    });

    udpateBelongsTo({
      relationData: updatedRelations,
      action: 'remove',
      relationElem: relationToRemove,
      relationId: key,
    });
    // setDataTableSchema({ ...dataTableSchema, relations: updatedRelations });
  };

  // const options = Object.keys(allDataTableData)
  //   .filter((key) => key !== dataTableSchema.name && allDataTableData[key].tableType !== 'junction')
  //   .map((key) => ({
  //     value: allDataTableData[key].name,
  //     label: allDataTableData[key].name,
  //   }));
  const options = useMemo(() => {
    return Object.keys(allDataTableData)
      .filter(
        // (key) => key !== dataTableSchema.name && allDataTableData[key].tableType !== 'junction'
        (key) => allDataTableData[key].tableType !== 'junction'
      )
      .map((key) => ({
        value: allDataTableData[key].name,
        label: allDataTableData[key].name,
      }));
  }, [allDataTableData, dataTableSchema.name]);
  const finalTargetTableOptions = useMemo(() => {
    return options;
    // options.filter((option) => {
    //   const isFound = Object.values(relations ?? {}).some((relationElem) => {
    //     return relationElem.target === option.value;
    //   });
    //   return !isFound;
    // });
  }, [options, relations]);

  return (
    <>
      <label className='form-label fs-6 fw-bolder text-dark mt-3'>{mls('Relations')}</label>
      <div
        style={{
          border: '1px solid #e4e6ef',
          borderRadius: '.5rem',
          padding: '.5rem',
        }}
      >
        <Form className='mb-5 ms-3'>
          <Row className='align-items-end'>
            <Col md={4}>
              <Form.Group controlId='relationTarget'>
                <Form.Label>{mls('Target Table')}</Form.Label>
                <Select
                  name='target'
                  value={options.find((option) => option.value === newRelations.target) || ''}
                  onChange={(selectedOption) =>
                    handleInputChange({
                      target: { name: 'target', value: selectedOption?.value ?? '' },
                    })
                  }
                  options={finalTargetTableOptions}
                  isInvalid={!!errors.target}
                  menuPlacement='auto'
                  menuPosition='fixed'
                  menuPortalTarget={document.body}
                  placeholder='Select Target Table'
                  isSearchable
                  styles={{
                    menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                  }}
                />
                {/* <Form.Control
                  as='select'
                  name='target'
                  value={newRelations.target}
                  onChange={handleInputChange}
                  isInvalid={!!errors.target}
                >
                  <option value=''>Select Target Table</option>
                  {Object.keys(allDataTableData).map((key) => (
                    <option key={key} value={allDataTableData[key].name}>
                      {allDataTableData[key].name}
                    </option>
                  ))}
                </Form.Control> */}
              </Form.Group>
            </Col>
            <Col md={3}>
              <Form.Group controlId='relationType'>
                <Form.Label>{mls('Type')}</Form.Label>
                <Select
                  name='type'
                  value={
                    relationTypeArrayOptions.find((option) => option.value === newRelations.type) ||
                    ''
                  }
                  onChange={(selectedOption) =>
                    handleInputChange({ target: { name: 'type', value: selectedOption.value } })
                  }
                  options={relationTypeArrayOptions}
                  placeholder='Select Type'
                  menuPlacement='auto'
                  isInvalid={!!errors.type}
                  menuPortalTarget={document.body}
                  styles={{
                    menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                  }}
                />
              </Form.Group>
            </Col>
            <Col md={3}>
              <Form.Group controlId='relationDisplayName'>
                <Form.Label>{mls('Relation Name')}</Form.Label>
                <Form.Control
                  type='text'
                  name='relationName'
                  placeholder='Relation Name'
                  value={newRelations.relationName}
                  onChange={handleInputChange}
                  style={{ height: '2.95rem' }}
                />
              </Form.Group>
            </Col>
            <Col md={2}>
              <Button
                variant='primary'
                onClick={handleAddRelation}
                className='btn btn-sm btn-primary'
                style={{ marginBottom: '4px' }}
              >
                {mls('Add Relation')}
              </Button>
            </Col>
          </Row>
        </Form>
        {/* Error Section */}
        {errors.length > 0 && (
          <div className='alert alert-danger' role='alert'>
            {errors}
          </div>
        )}
        {Object.keys(relations).length > 0 && (
          <>
            <hr />
            <label className='form-label fs-6 fw-bolder text-dark'>
              {mls('Current Relations')}
            </label>
            <div className='m-3'>
              <Row
                className='fw-bold mb-2 p-2'
                style={{
                  borderBottom: 'solid 1px #DFE2EF',
                  // borderRadius: '6px',
                }}
              >
                <Col md={4}>{mls('Target Table')}</Col>
                <Col md={3}>{mls('Type')}</Col>
                <Col md={3}>{mls('Relation Name')}</Col>
                <Col md={2}></Col>
              </Row>
              {Object.keys(relations).map((key) => {
                const relationsElem = relations[key];
                return (
                  <Form
                    key={key}
                    className='mb-3 p-2'
                    style={{
                      borderBottom: 'solid 1px #EFF0F7',
                      // borderRadius: '6px',
                    }}
                  >
                    <Row className='align-items-center'>
                      <Col md={4}>
                        {relationsElem?.target}
                        {/* <Form.Group controlId={`relationTarget-${key}`}>
                          <Select
                            name='target'
                            value={options.find((option) => option.value === relations[key].target)}
                            onChange={(selectedOption) =>
                              handleUpdateRelation(
                                { target: { name: 'target', value: selectedOption.value } },
                                key
                              )
                            }
                            options={options}
                            placeholder='Select Target Table'
                            menuPlacement='auto'
                            isSearchable
                          />
                        </Form.Group> */}
                      </Col>

                      <Col md={3}>
                        {relationsElem?.type}
                        {/* <Form.Group controlId={`relationType-${key}`}>
                          <Select
                            name='type'
                            value={relationTypeArrayOptions.find(
                              (option) => option.value === relations[key].type
                            )}
                            onChange={(selectedOption) =>
                              handleUpdateRelation(
                                { target: { name: 'type', value: selectedOption.value } },
                                key
                              )
                            }
                            options={relationTypeArrayOptions}
                            menuPlacement='auto'
                            placeholder='Select Type'
                            isInvalid={!!errors.type}
                          />
                        </Form.Group> */}
                      </Col>
                      <Col md={3}>
                        {relationsElem?.relationName}
                        {/* <Form.Group controlId={`relationType-${key}`}>
                          <Select
                            name='type'
                            value={relationTypeArrayOptions.find(
                              (option) => option.value === relations[key].type
                            )}
                            onChange={(selectedOption) =>
                              handleUpdateRelation(
                                { target: { name: 'type', value: selectedOption.value } },
                                key
                              )
                            }
                            options={relationTypeArrayOptions}
                            menuPlacement='auto'
                            placeholder='Select Type'
                            isInvalid={!!errors.type}
                          />
                        </Form.Group> */}
                      </Col>

                      <Col md={2} className='d-flex justify-content-center'>
                        <XCircleFill
                          color='grey'
                          size={20}
                          style={{ cursor: 'pointer' }}
                          onClick={() => handleRemoveRelation(key)}
                        />
                      </Col>
                    </Row>
                    {/* <Row className='align-items-center'>
                      <Col md={5}>
                        <Form.Group controlId={`relationTarget-${key}`}>
                          <Select
                            name='target'
                            value={options.find((option) => option.value === relations[key].target)}
                            onChange={(selectedOption) =>
                              handleUpdateRelation(
                                { target: { name: 'target', value: selectedOption.value } },
                                key
                              )
                            }
                            options={options}
                            placeholder='Select Target Table'
                            menuPlacement='auto'
                            isSearchable
                          />
                        </Form.Group>
                      </Col>

                      <Col md={5}>
                        {key}
                        {relations[key].type}
                        <Form.Group controlId={`relationType-${key}`}>
                          <Select
                            name='type'
                            value={relationTypeArrayOptions.find(
                              (option) => option.value === relations[key].type
                            )}
                            onChange={(selectedOption) =>
                              handleUpdateRelation(
                                { target: { name: 'type', value: selectedOption.value } },
                                key
                              )
                            }
                            options={relationTypeArrayOptions}
                            menuPlacement='auto'
                            placeholder='Select Type'
                            isInvalid={!!errors.type}
                          />
                        </Form.Group>
                      </Col>

                      <Col md={2} className='d-flex justify-content-center'>
                        <XCircleFill
                          color='grey'
                          size={20}
                          style={{ cursor: 'pointer' }}
                          onClick={() => handleRemoveRelation(key)}
                        />
                      </Col>
                    </Row> */}
                  </Form>
                );
              })}
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default RelationsComponent;
