export const relationNestedLevel = 2;
interface componentUpdateFunctionProps {
  allComponentSchema: { [key: string]: any };
  targetedTableData: {
    tableID: string;
    operation: 'DELETE' | 'UPDATE';
    originalDataTableSchema: { [key: string]: any };
    currentDataTableSchema: { [key: string]: any };
  }[];
  tableUpdates: any;
}
export const componentUpdateFunction = ({
  allComponentSchema,
  targetedTableData,
}: componentUpdateFunctionProps) => {
  let originalComponent: { [key: string]: any } = {};
  let affectedComponent: { [key: string]: any } = {};

  Object.keys(allComponentSchema).forEach((componentElemKey: string) => {
    const componentElem = allComponentSchema[componentElemKey];
    let affected = false;
    let updatedComponentSchema = { ...componentElem };
    (targetedTableData ?? []).forEach((targetedTableElem: any) => {
      if (targetedTableElem?.operation === 'DELETE') {
        if (componentElem?.table === targetedTableElem?.tableID) {
          delete updatedComponentSchema.tableList;
          delete updatedComponentSchema.table;
          delete updatedComponentSchema.codeCustomization;
          delete updatedComponentSchema.template;
          affected = true;
        } else {
          const udpateTable = getUpdatedTableList({
            componentElem,
            targetedTableElem,
            nestedLevel: relationNestedLevel,
          });
          if (udpateTable?.affected) {
            affected = udpateTable?.affected;
            updatedComponentSchema.tableList = udpateTable?.updatedTableList;
          }
        }
      }
      if (targetedTableElem?.operation === 'UPDATE') {
        if (componentElem?.table === targetedTableElem?.tableID) {
          const currentDataTableSchema = targetedTableElem?.currentDataTableSchema;
          const originalDataTableSchema = targetedTableElem?.originalDataTableSchema;

          const { deleteRelationsList, adddRelationList } = getRelationsUpdateData({
            currentDataTableSchema,
            originalDataTableSchema,
          });

          const data = handleDeleteRelation({
            deleteRelationsList,
            mainTableID: targetedTableElem?.tableID,
            allComponentSchema,
          });
        } else {
          // const udpateTable = getUpdatedTableList({
          //   componentElem,
          //   targetedTableElem,
          //   nestedLevel:relationNestedLevel ,
          // });
          // if (udpateTable?.affected) {
          //   affected = udpateTable?.affected;
          //   updatedComponentSchema.tableList = udpateTable?.updatedTableList;
          // }
        }
      }
    });
    if (affected) {
      affectedComponent = { ...affectedComponent, [componentElemKey]: updatedComponentSchema };
      originalComponent = { ...originalComponent, [componentElemKey]: componentElem };
    }
  });
  return { affectedComponent, originalComponent };
};

export const componentUpdateFunctionOld = ({
  allComponentSchema,
  targetedTableData,
}: componentUpdateFunctionProps) => {
  let originalComponent: { [key: string]: any } = {};
  let affectedComponent: { [key: string]: any } = {};

  Object.keys(allComponentSchema).forEach((componentElemKey: string) => {
    const componentElem = allComponentSchema[componentElemKey];

    let affected = false;
    // if (componentElem?.name === 'NEW Table test') {
    //   console.log(componentElem);
    // }
    let isOuterDeleted = false;
    let updatedComponentSchema = { ...componentElem };

    // Handle Outer TableList
    (targetedTableData ?? []).forEach((targetedTableElem: any) => {
      if (componentElem?.table === targetedTableElem?.tableID) {
        if (targetedTableElem?.operation === 'DELETE') {
          delete updatedComponentSchema.tableList;
          delete updatedComponentSchema.table;
          delete updatedComponentSchema.codeCustomization;
          // delete updatedComponentSchema.table;
          delete updatedComponentSchema.emailTemplate;
          isOuterDeleted = true;
        }
        if (targetedTableElem?.operation === 'UPDATE') {
          const currentDataTableSchema = targetedTableElem?.currentDataTableSchema;
          const originalDataTableSchema = targetedTableElem?.originalDataTableSchema;

          const { deleteRelationsList, adddRelationList } = getRelationsUpdateData({
            currentDataTableSchema,
            originalDataTableSchema,
          });
          const handleDeleteRelation = ({
            deleteRelationsList,
            mainTableID,
            allComponentSchema,
          }: {
            deleteRelationsList: { [key: string]: any };
            allComponentSchema: { [key: string]: any };
            mainTableID: string;
          }) => {
            let updatedDeleteRelationTableList: { [key: string]: any } = {};
            // Outer Table Check
            Object.keys(allComponentSchema).forEach((componentElemKey: string) => {
              const componentElem = allComponentSchema[componentElemKey];
              // if main table id and component id matched then remove realtions from tableList.
              if (componentElem?.table === mainTableID) {
                const getRemovedUpdatedTableList = ({
                  deleteRelationsList,
                  componentElem,
                  nestingLevel,
                }: {
                  deleteRelationsList: { [key: string]: any };
                  componentElem: { [key: string]: any };
                  nestingLevel: number;
                }) => {
                  let updatedTableList: { [key: string]: any }[] = [];
                  let tableListAffected = false;
                  if (Array.isArray(componentElem?.tableList)) {
                    (componentElem?.tableList ?? []).forEach((tableListElem: any) => {
                      let isTableListElemDeleted = false;
                      Object.keys(deleteRelationsList).forEach((deleteRelationsElemKey: string) => {
                        const deleteRelationsElem = deleteRelationsList[deleteRelationsElemKey];
                        // if (deleteRelationsElem?.target === tableListElem?.tableID) {
                        if (deleteRelationsElem?.as === tableListElem?.relationID) {
                          isTableListElemDeleted = true;
                          tableListAffected = true;
                        }
                      });
                      if (!isTableListElemDeleted) {
                        let newTableListElem = { ...tableListElem };
                        if (nestingLevel >= 0) {
                          const { updatedTableList, tableListAffected: innerTableListAffected } =
                            getRemovedUpdatedTableList({
                              deleteRelationsList,
                              componentElem: tableListElem,
                              nestingLevel: nestingLevel - 1,
                            });
                          if (innerTableListAffected) {
                            newTableListElem = { ...newTableListElem, tableList: updatedTableList };
                            tableListAffected = innerTableListAffected;
                          }
                        }
                        updatedTableList = [...updatedTableList, newTableListElem];
                      }
                    });
                  }
                  return { updatedTableList, tableListAffected };
                };
                const { updatedTableList, tableListAffected } = getRemovedUpdatedTableList({
                  deleteRelationsList,
                  componentElem: componentElem,
                  nestingLevel: relationNestedLevel - 2,
                });

                // console.log(updatedTableList, 'updatedTableList');
                if (tableListAffected) {
                  updatedDeleteRelationTableList = {
                    ...updatedDeleteRelationTableList,
                    [componentElemKey]: { ...componentElem, tableList: updatedTableList },
                  };
                }
                // console.log(componentElem?.tableList, 'componentElem?.tableList');
              }
              // console.log(componentElem?.table);
              // console.log(componentElemKey);
            });
            return updatedDeleteRelationTableList;
          };
          const data = handleDeleteRelation({
            deleteRelationsList,
            mainTableID: targetedTableElem?.tableID,
            allComponentSchema,
          });
          console.log(data);

          // delete updatedComponentSchema.tableList;
          // delete updatedComponentSchema.table;
          // delete updatedComponentSchema.codeCustomization;
          // // delete updatedComponentSchema.table;
          // delete updatedComponentSchema.emailTemplate;
          // isOuterDeleted = true;
        }

        affected = true;
      }
    });

    if (isOuterDeleted) {
      affectedComponent = { ...affectedComponent, [componentElemKey]: updatedComponentSchema };
      originalComponent = { ...originalComponent, [componentElemKey]: componentElem };
      return;
    }
    (targetedTableData ?? []).forEach((targetedTableElem: any) => {
      if (targetedTableElem?.operation === 'DELETE') {
        const udpateTable = getUpdatedTableList({
          componentElem,
          targetedTableElem,
          nestedLevel: relationNestedLevel,
        });
        if (udpateTable?.affected) {
          affected = udpateTable?.affected;
          updatedComponentSchema.tableList = udpateTable?.updatedTableList;
        }
      }
    });

    // Handle TableList
    // let updatedTableList: { [key: string]: any }[] = [];
    // if (Array.isArray(componentElem?.tableList)) {
    //   (componentElem?.tableList ?? []).forEach((tableListElem: any) => {
    //     let updatedTableListElem = { ...tableListElem };
    //     let isTableListElemDeleted = false;
    //     (targetedTableData ?? []).forEach((targetedTableElem: any) => {
    //       if (tableListElem?.tableID === targetedTableElem?.tableID) {
    //         if (targetedTableElem?.operation === 'DELETE') {
    //           isTableListElemDeleted = true;
    //           affected = true;
    //           return;
    //         }
    //         if (targetedTableElem?.operation === 'UPDATE') {
    //           //addColumns
    //           (targetedTableElem?.tableUpdates?.addColumns ?? []).forEach((addColumnsElem: any) => {
    //             updatedTableListElem.defaultView = [
    //               ...(updatedTableListElem?.defaultView ?? []),
    //               getDefaultViewElem({ columnData: addColumnsElem }),
    //             ];
    //             affected = true;
    //           });
    //           // //addColumns
    //           // (targetedTableElem?.tableUpdates?.addColumns ?? []).forEach((addColumnsElem: any) => {
    //           //   updatedTableListElem.defaultView = [
    //           //     ...(updatedTableListElem?.defaultView ?? []),
    //           //     getDefaultViewElem({ columnData: addColumnsElem }),
    //           //   ];
    //           //   affected = true;
    //           // });

    //           // updatedComponentSchema.tableList = {...updatedComponentSchema.tableList};
    //           // delete updatedComponentSchema.table;
    //           // delete updatedComponentSchema.codeCustomization;
    //           // // delete updatedComponentSchema.table;
    //           // delete updatedComponentSchema.emailTemplate;
    //           // isOuterDeleted = true;
    //         }
    //       }
    //     });
    //     if (!isTableListElemDeleted) {
    //       if (Array.isArray(tableListElem?.tableList)) {
    //         let updatedInnerTableList: any[] = [];
    //         (tableListElem?.tableList ?? []).forEach((innertableListElem: any) => {
    //           let updatedInnerTableListElem = { ...innertableListElem };
    //           let isTableListInnerElemDeleted = false;
    //           (targetedTableData ?? []).forEach((targetedTableElem: any) => {
    //             if (innertableListElem?.tableID === targetedTableElem?.tableID) {
    //               if (targetedTableElem?.operation === 'DELETE') {
    //                 isTableListInnerElemDeleted = true;
    //                 affected = true;
    //                 return;
    //               }
    //               if (targetedTableElem?.operation === 'UPDATE') {
    //                 //addColumns
    //                 (targetedTableElem?.tableUpdates?.addColumns ?? []).forEach(
    //                   (addColumnsElem: any) => {
    //                     updatedInnerTableListElem.defaultView = [
    //                       ...(updatedInnerTableListElem?.defaultView ?? []),
    //                       getDefaultViewElem({ columnData: addColumnsElem }),
    //                     ];
    //                     affected = true;
    //                   }
    //                 );

    //                 // updatedComponentSchema.tableList = {...updatedComponentSchema.tableList};
    //                 // console.log(updatedComponentSchema.tableList);
    //                 // delete updatedComponentSchema.table;
    //                 // delete updatedComponentSchema.codeCustomization;
    //                 // // delete updatedComponentSchema.table;
    //                 // delete updatedComponentSchema.emailTemplate;
    //                 // isOuterDeleted = true;
    //               }
    //             }
    //           });
    //           if (!isTableListInnerElemDeleted) {
    //             updatedInnerTableList = [...updatedInnerTableList, updatedInnerTableListElem];
    //           }
    //         });
    //         updatedTableList = [
    //           ...updatedTableList,
    //           { ...updatedTableListElem, tableList: updatedInnerTableList },
    //         ];
    //       }
    //     }
    //   });
    // }
    // updatedComponentSchema.tableList = updatedTableList;

    if (affected) {
      affectedComponent = { ...affectedComponent, [componentElemKey]: updatedComponentSchema };
      originalComponent = { ...originalComponent, [componentElemKey]: componentElem };
    }
  });
  console.log(affectedComponent);
  console.log(originalComponent);
};
const getUpdatedTableList = ({
  componentElem,
  targetedTableElem,
  nestedLevel,
}: {
  componentElem: any;
  targetedTableElem: any;
  nestedLevel: number;
}) => {
  let affected = false;
  // Handle TableList
  let updatedTableList: { [key: string]: any }[] = [];
  if (Array.isArray(componentElem?.tableList)) {
    (componentElem?.tableList ?? []).forEach((tableListElem: any) => {
      let updatedTableListElem = { ...tableListElem };
      let isTableListElemDeleted = false;
      if (tableListElem?.tableID === targetedTableElem?.tableID) {
        isTableListElemDeleted = true;
        affected = true;
        return;
      }
      if (!isTableListElemDeleted) {
        if (nestedLevel >= 0) {
          const { updatedTableList: updatedInnerTableList, affected: affectedInner } =
            getUpdatedTableList({
              componentElem: tableListElem,
              targetedTableElem,
              nestedLevel: nestedLevel - 1,
            });
          if (affectedInner) {
            updatedTableListElem = { ...updatedTableListElem, tableList: updatedInnerTableList };
            affected = true;
          }
          updatedTableList = [...updatedTableList, updatedTableListElem];
        }
      }
    });
  }
  return { affected, updatedTableList };
};
const handleDeleteRelation = ({
  deleteRelationsList,
  mainTableID,
  allComponentSchema,
}: {
  deleteRelationsList: { [key: string]: any };
  allComponentSchema: { [key: string]: any };
  mainTableID: string;
}) => {
  let updatedDeleteRelationTableList: { [key: string]: any } = {};
  // Outer Table Check
  Object.keys(allComponentSchema).forEach((componentElemKey: string) => {
    const componentElem = allComponentSchema[componentElemKey];
    // if main table id and component id matched then remove realtions from tableList.
    if (componentElem?.table === mainTableID) {
      const getRemovedUpdatedTableList = ({
        deleteRelationsList,
        componentElem,
        nestingLevel,
      }: {
        deleteRelationsList: { [key: string]: any };
        componentElem: { [key: string]: any };
        nestingLevel: number;
      }) => {
        let updatedTableList: { [key: string]: any }[] = [];
        let tableListAffected = false;
        if (Array.isArray(componentElem?.tableList)) {
          (componentElem?.tableList ?? []).forEach((tableListElem: any) => {
            let isTableListElemDeleted = false;
            Object.keys(deleteRelationsList).forEach((deleteRelationsElemKey: string) => {
              const deleteRelationsElem = deleteRelationsList[deleteRelationsElemKey];
              // if (deleteRelationsElem?.target === tableListElem?.tableID) {
              if (deleteRelationsElem?.as === tableListElem?.relationID) {
                isTableListElemDeleted = true;
                tableListAffected = true;
              }
            });
            if (!isTableListElemDeleted) {
              let newTableListElem = { ...tableListElem };
              if (nestingLevel >= 0) {
                const { updatedTableList, tableListAffected: innerTableListAffected } =
                  getRemovedUpdatedTableList({
                    deleteRelationsList,
                    componentElem: tableListElem,
                    nestingLevel: nestingLevel - 1,
                  });
                if (innerTableListAffected) {
                  newTableListElem = { ...newTableListElem, tableList: updatedTableList };
                  tableListAffected = innerTableListAffected;
                }
              }
              updatedTableList = [...updatedTableList, newTableListElem];
            }
          });
        }
        return { updatedTableList, tableListAffected };
      };
      const { updatedTableList, tableListAffected } = getRemovedUpdatedTableList({
        deleteRelationsList,
        componentElem: componentElem,
        nestingLevel: relationNestedLevel - 2,
      });

      // console.log(updatedTableList, 'updatedTableList');
      if (tableListAffected) {
        updatedDeleteRelationTableList = {
          ...updatedDeleteRelationTableList,
          [componentElemKey]: { ...componentElem, tableList: updatedTableList },
        };
      }
      // console.log(componentElem?.tableList, 'componentElem?.tableList');
    }
    // console.log(componentElem?.table);
    // console.log(componentElemKey);
  });
  return updatedDeleteRelationTableList;
};
const getRelationsUpdateData = ({
  currentDataTableSchema,
  originalDataTableSchema,
}: {
  currentDataTableSchema: { [key: string]: any };
  originalDataTableSchema: { [key: string]: any };
}) => {
  let deleteRelationsList: { [key: string]: any } = {};
  let adddRelationList: { [key: string]: any } = {};
  Object.keys(originalDataTableSchema?.relations).forEach((originalDataTableElemKey: string) => {
    const currentDataTableElem = originalDataTableSchema?.relations?.[originalDataTableElemKey];
    deleteRelationsList = {
      ...deleteRelationsList,
      [originalDataTableElemKey]: currentDataTableElem,
    };
  });
  Object.keys(currentDataTableSchema?.relations).forEach((currentDataTableElemKey: string) => {
    if (deleteRelationsList?.[currentDataTableElemKey]) {
      delete deleteRelationsList?.[currentDataTableElemKey];
    } else {
      const currentDataTableElem = currentDataTableSchema?.relations?.[currentDataTableElemKey];
      adddRelationList = {
        ...adddRelationList,
        [currentDataTableElemKey]: currentDataTableElem,
      };
    }
  });
  return { deleteRelationsList, adddRelationList };
};
