import React, { useMemo, useState } from 'react';
import { Row, Col, Form } from 'react-bootstrap';

import styles from './codeCustomization.module.scss';
import { mls } from 'lib/multilanguagesupport';
import AddTeamsPopup from '../../AddTeamsPopup/AddTeamsPopup';
import { getAppComponentColumnSchema } from '../PDFTemplates/helperFuntion';
import RoleComponentFilter from '../../RoleComponentFilter/RoleComponentFilter';
import { SolidColorPopupWrapper } from 'lib/reusable-components/Components/SolidColorSelector/SolidColorSelector';
import { buttonTypeObject } from '../../data/appComponentData';
import MoreActions from './AICustomizationEditorContainer/MoreActions';
import { FaFilter, FaUser } from 'react-icons/fa';
import { IoSettings } from 'react-icons/io5';
import { getAppComponentFilterColumnSchema } from '../../utils/componentSettingFunction';
import TimeIntervalField from './TimeInterval/TimeIntervalField';
const QueryContainerPermissionContainer = ({
  componentSchema,
  title,
  setTitle,
  activeButtonKey,
  errors,
  setErrors,
  selectedCode,
  updateCodeCustomization,
  selectedTable,
  connectedTablesData,
  selectedParentId,
  componentRowList,
  selectedId,
  buttonType,
  isRelatedTypeTable,
  handleSelectedParentId,
  handleSelectedId,
  isSampleDataPopup,
  setIsSampleDataPopup,
  sampleData,
  isMoreOptionsOpen,
  groupedConnectedTablesData,
  selectedTableData,
}: {
  componentSchema: any;
  title: string;
  setTitle: Function;
  activeButtonKey: string;
  setComponentSchema: Function;
  errors: any;
  connectedTablesData: { [key: string]: any };
  groupedConnectedTablesData: { [key: string]: any };
  setErrors: Function;
  selectedCode: { [key: string]: any };
  selectedTable: string;
  updateCodeCustomization: Function;

  selectedParentId: string;
  componentRowList: any;
  selectedId: any;
  buttonType: string;

  isRelatedTypeTable: boolean;
  handleSelectedParentId: Function;
  handleSelectedId: Function;
  isSampleDataPopup: boolean;
  setIsSampleDataPopup: Function;
  sampleData: any;
  isMoreOptionsOpen: boolean;
  setIsMoreOptionsOpen: Function;
  selectedTableData: any;
}) => {
  const columnSchema = useMemo(() => {
    const finalColumns = getAppComponentFilterColumnSchema({
      groupedConnectedTablesData,
      connectedTablesData,
      tableData: selectedTableData,
      finalTableID: selectedTable,
    });
    return finalColumns;
  }, [connectedTablesData, selectedTableData, selectedTable, groupedConnectedTablesData]);

  const validateFields = () => {
    const errors: { [key: string]: any } = {};
    if (!title.trim()) {
      errors.title = 'Label is required.';
    }
    setErrors(errors);
    return Object.keys(errors).length === 0;
  };
  const handleLabelChange = (e: any) => {
    setTitle(e.target.value);
  };
  const [isDisplayWhenPopup, setIsDisplayWhenPopup] = useState(false);
  const [isDisplayToPopup, setIsDisplayToPopup] = useState(false);
  const [isMoreActionPopup, setIsMoreActionPopup] = useState(false);
  const handleFilterPopup = () => {
    setIsDisplayWhenPopup(!isDisplayWhenPopup);
  };
  const handleDisplayToPopup = () => {
    setIsDisplayToPopup(!isDisplayToPopup);
  };
  const udpateActionButtonData = ({
    activeButtonKey,
    activeButtonData,
  }: {
    activeButtonKey: string;
    activeButtonData: { [key: string]: any };
  }) => {
    if (buttonType === buttonTypeObject.actionButton.key) {
      updateCodeCustomization({
        selectedTable,
        codeCustomizationData: {
          ...selectedCode,
          actionButton: {
            ...(selectedCode?.actionButton ?? {}),
            [activeButtonKey]: activeButtonData,
          },
        },
      });
    }
    if (buttonType === buttonTypeObject.cronJob.key) {
      updateCodeCustomization({
        selectedTable,
        codeCustomizationData: {
          ...selectedCode,
          cronJob: {
            ...(selectedCode?.cronJob ?? {}),
            [activeButtonKey]: activeButtonData,
          },
        },
      });
    }
  };
  const handleSaveActionButtonTitle = () => {
    if (validateFields()) {
      udpateActionButtonData({
        activeButtonKey,
        activeButtonData: {
          ...(actionButton?.[activeButtonKey] ?? {}),
          title,
        },
      });
    }
  };
  const actionButton =
    buttonType === buttonTypeObject.actionButton.key
      ? componentSchema?.codeCustomization?.[selectedTable]?.actionButton ?? {}
      : componentSchema?.codeCustomization?.[selectedTable]?.cronJob ?? {};

  const handleDisplayWhen = (displayWhen: any) => {
    udpateActionButtonData({
      activeButtonKey,
      activeButtonData: {
        ...(actionButton?.[activeButtonKey] ?? {}),
        displayWhen,
      },
    });
  };
  const handleDisplayTo = (displayTo: any) => {
    udpateActionButtonData({
      activeButtonKey,
      activeButtonData: {
        ...(actionButton?.[activeButtonKey] ?? {}),
        displayTo,
      },
    });
  };
  const filterLength = ({ filter }: { filter: any }) => {
    const filterLength = Object.keys(filter)?.length;

    if (filterLength > 0) {
      return <div className={styles.filterNumber}>{filterLength}</div>;
    }
    return '';
  };

  const handleActionButtonColor = (actionButtonColor: any) => {
    udpateActionButtonData({
      activeButtonKey,
      activeButtonData: {
        ...(actionButton?.[activeButtonKey] ?? {}),
        actionButtonColor,
      },
    });
  };
  const updateMoreActionFunction = (actionOrder: any) => {
    udpateActionButtonData({
      activeButtonKey,
      activeButtonData: {
        ...(actionButton?.[activeButtonKey] ?? {}),
        actionOrder,
      },
    });
  };

  if (
    buttonType !== buttonTypeObject.actionButton.key &&
    buttonType !== buttonTypeObject.cronJob.key
  ) {
    return null;
  }

  const fieldsElem = {
    // label: mls('Scheduler'),
    name: 'scheduler',
  };
  const setFormData = (action: any) => {
    // setTemplateData({
    //   ...templateData,
    //   [action?.key]: action?.value,
    // });
  };
  const handleScheduler = (action: any) => {
    udpateActionButtonData({
      activeButtonKey,
      activeButtonData: {
        ...(actionButton?.[activeButtonKey] ?? {}),
        [action?.key]: action?.value,
      },
    });
  };
  return (
    <>
      <div className={styles.editorHeader} style={{ marginBottom: 0 }}>
        {isDisplayToPopup ? (
          <AddTeamsPopup
            isDisplayToPopup={isDisplayToPopup}
            setIsDisplayToPopup={setIsDisplayToPopup}
            setSelectedTeams={handleDisplayTo}
            selectedTeams={actionButton?.[activeButtonKey]?.displayTo ?? []}
          />
        ) : null}
        {isDisplayWhenPopup ? (
          <RoleComponentFilter
            setChartFilter={handleDisplayWhen}
            allTableData={columnSchema}
            chartFilter={actionButton?.[activeButtonKey]?.displayWhen ?? {}}
            appID={''}
            sheetID={''}
            isJoinedTable={false}
            isPopUp={isDisplayWhenPopup}
            setIsPopUp={setIsDisplayWhenPopup}
          />
        ) : null}
        <div className={styles.queryContainerPermissionOuterContainer}>
          <div className={styles.queryContainerPermissionContainer}>
            {buttonType === buttonTypeObject.actionButton.key ? (
              <>
                <Form.Group controlId='actionButtonLabel' className={styles.actionButtonOptions}>
                  <Form.Label className={styles.actionButtonOptionsLabel}>
                    {mls('Action Name')}:
                  </Form.Label>
                  <Form.Control
                    type='text'
                    value={title}
                    style={{ maxWidth: '15rem' }}
                    onChange={handleLabelChange}
                    onBlur={() => handleSaveActionButtonTitle()}
                    isInvalid={!!errors?.title}
                  />
                  <Form.Control.Feedback type='invalid'>{errors?.title}</Form.Control.Feedback>
                </Form.Group>
                <div className={styles.displayWhenFilterContainer}>
                  <b>{mls('Action Color')}:</b>
                  <div>
                    <SolidColorPopupWrapper
                      color={{ color: actionButton?.[activeButtonKey]?.actionButtonColor }}
                      setColor={(e: any) => {
                        handleActionButtonColor(e?.color);
                      }}
                    />
                  </div>
                </div>
                <div className={`${styles.displayWhenFilterContainer}`}>
                  <b>{mls('Actions')}:</b>
                  <div className={styles.filterIconContainer}>
                    <button
                      className='primaryButton secondaryButtonColor'
                      onClick={() => {
                        handleFilterPopup();
                      }}
                      style={{ width: '100%' }}
                      title={mls('Display When')}
                    >
                      <FaFilter />
                    </button>
                    {filterLength({ filter: actionButton?.[activeButtonKey]?.displayWhen ?? {} })}
                  </div>
                  <button
                    className='primaryButton secondaryButtonColor'
                    onClick={() => handleDisplayToPopup()}
                    title={mls('Select Teams')}
                  >
                    <FaUser />
                  </button>
                  {buttonType === buttonTypeObject.actionButton.key ? (
                    <>
                      <button
                        className='primaryButton secondaryButtonColor'
                        onClick={() => setIsMoreActionPopup(true)}
                        title={mls('More Actions')}
                      >
                        <IoSettings />
                      </button>
                      {isMoreActionPopup ? (
                        <MoreActions
                          isMoreActionPopup={isMoreActionPopup}
                          setIsMoreActionPopup={setIsMoreActionPopup}
                          componentSchema={componentSchema}
                          selectedTable={selectedTable}
                          updateMoreActionFunction={updateMoreActionFunction}
                          selectedData={actionButton?.[activeButtonKey]}
                        />
                      ) : null}
                    </>
                  ) : null}
                </div>
              </>
            ) : null}
            {buttonType === buttonTypeObject.cronJob.key ? (
              <>
                <Form.Group controlId='actionButtonLabel' className={styles.actionButtonOptions}>
                  <Form.Label className={styles.actionButtonOptionsLabel}>
                    {mls('Scheduler Name')}:
                  </Form.Label>
                  <Form.Control
                    type='text'
                    value={title}
                    style={{ maxWidth: '15rem' }}
                    onChange={handleLabelChange}
                    onBlur={() => handleSaveActionButtonTitle()}
                    isInvalid={!!errors?.title}
                  />
                  <Form.Control.Feedback type='invalid'>{errors?.title}</Form.Control.Feedback>
                </Form.Group>
                {/* <div className={styles.displayWhenFilterContainer}>
                  <b>{mls('Button Color')}:</b>
                  <div>
                    <SolidColorPopupWrapper
                      color={{ color: actionButton?.[activeButtonKey]?.actionButtonColor }}
                      setColor={(e: any) => {
                        handleActionButtonColor(e?.color);
                      }}
                    />
                  </div>
                </div> */}
                <div className={`${styles.displayWhenFilterContainer}`}>
                  <b>{mls('Actions')}:</b>
                  <div className={styles.filterIconContainer}>
                    <button
                      className='primaryButton secondaryButtonColor'
                      onClick={() => {
                        handleFilterPopup();
                      }}
                      style={{ width: '100%' }}
                      title={mls('Display When')}
                    >
                      <FaFilter />
                    </button>
                    {filterLength({ filter: actionButton?.[activeButtonKey]?.displayWhen ?? {} })}
                  </div>
                  <button
                    className='primaryButton secondaryButtonColor'
                    onClick={() => handleDisplayToPopup()}
                    title={mls('Select Teams')}
                  >
                    <FaUser />
                  </button>
                  {buttonType === buttonTypeObject.actionButton.key ? (
                    <>
                      <button
                        className='primaryButton secondaryButtonColor'
                        onClick={() => setIsMoreActionPopup(true)}
                        title={mls('More Actions')}
                      >
                        <IoSettings />
                      </button>
                      {isMoreActionPopup ? (
                        <MoreActions
                          isMoreActionPopup={isMoreActionPopup}
                          setIsMoreActionPopup={setIsMoreActionPopup}
                          componentSchema={componentSchema}
                          selectedTable={selectedTable}
                          updateMoreActionFunction={updateMoreActionFunction}
                          selectedData={actionButton?.[activeButtonKey]}
                        />
                      ) : null}
                    </>
                  ) : null}
                  <TimeIntervalField
                    fieldsElem={fieldsElem}
                    formData={actionButton?.[activeButtonKey]}
                    setFormData={handleScheduler}
                  />
                </div>
              </>
            ) : null}
          </div>
        </div>
      </div>
      {/* {buttonType === buttonTypeObject.actionButton.key && isMoreOptionsOpen ? (
        <div className={styles.editorHeader} style={{ marginBottom: 0 }}>
          <div className={styles.queryContainerPermissionOuterContainer}>
            <div className={styles.queryContainerPermissionContainer}>
            
            </div>
          </div>
        </div>
      ) : null} */}
    </>
  );
};

export default QueryContainerPermissionContainer;
