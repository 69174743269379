//  For charts server
// import getAppID from './getAppID';

const analyticsApi =
  process.env.REACT_APP_IS_LOCALHOST === 'true'
    ? 'http://localhost:8000/analytics-api'
    : 'https://analytics-api.supista.com/analytics-api';

//  For Supista server
const originURL = window.location.origin; // Get the current origin

export const analyticsBaseAPI =
  originURL.includes('localhost') || originURL.includes('settings.supista.com')
    ? analyticsApi
    : originURL + '/analytics-api'; // Default to '/api' on the same origin

// Set the server URL based on the origin
const serverURL =
  originURL.includes('localhost') || originURL.includes('settings.supista.com')
    ? 'https://test.supista.com/api'
    : originURL + '/api'; // Default to '/api' on the same origin

const SupistaMISSettingsAPI = serverURL; //'http://localhost:8080/api'; // 'http://localhost:8080/api';

// const checkFor403Error = (response) => {
//   if (response?.status === 403) {
//     const appID = getAppID();
//     _supistaApiPost(`auth/${appID}/logout`, { data: {} }).then(() => {
//       localStorage.clear();
//       window.location.reload();
//     });
//     return true;
//   }
//   return false;
// };

export async function _fetchDataToPlot(params, url) {
  const serverURL = `${analyticsBaseAPI}/${url}`;
  return fetch(serverURL, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
    },
    body: JSON.stringify(params),
    credentials: 'include',
  })
    .then((response) => response.json())
    .then((response) => {
      // if (checkFor403Error(response)) return {};
      return response;
    })
    .catch((error) => {
      console.error('Error:', error);
      return {};
      // Handle any errors that occurred during the file upload
    });
}

export const _supistaAnalyticsApi = async (endpoint, data) => {
  return fetch(`${analyticsBaseAPI}/${endpoint}`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    credentials: 'include',
    body: JSON.stringify(data),
  })
    .then((response) => response.json())
    .then((response) => {
      // if (checkFor403Error(response)) return {};
      return response;
    })
    .catch((error) => {
      console.error('Error:', error);
      return {};
      // Handle any errors that occurred during the file upload
    });
};

export const _supistaApiGet = (endpoint) => {
  return fetch(`${SupistaMISSettingsAPI}/${endpoint}`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
    },
    credentials: 'include',
  })
    .then((response) => response.json())
    .catch((error) => {
      console.error('Error:', error);
      return {};
      // Handle any errors that occurred during the file upload
    });
};
export const _supistaApiGetRawResponse = (endpoint) => {
  return fetch(`${SupistaMISSettingsAPI}/${endpoint}`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
    },
    credentials: 'include',
  })
    .then((response) => response)
    .catch((error) => {
      console.error('Error:', error);
      return {};
      // Handle any errors that occurred during the file upload
    });
};

export const _supistaApiPost = (endpoint, data) => {
  const cookiesStoredInLocalStorage = JSON.parse(localStorage.getItem('__d3__cookies'));
  if (cookiesStoredInLocalStorage) {
    data.__d3__cookies = cookiesStoredInLocalStorage;
  } else {
    data.__d3__cookies = {};
  }
  return fetch(`${SupistaMISSettingsAPI}/${endpoint}`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    credentials: 'include',
    body: JSON.stringify(data),
  })
    .then((response) => response.json())
    .then((response) => {
      if (response.hasOwnProperty('__d3__cookies')) {
        localStorage.setItem('__d3__cookies', JSON.stringify(response.__d3__cookies));
      }
      return response;
    })
    .catch((error) => {
      console.error('Error:', error);
      return {};
      // Handle any errors that occurred during the file upload
    });
};
export const _supistaApiPut = (endpoint, data) => {
  const cookiesStoredInLocalStorage = JSON.parse(localStorage.getItem('__d3__cookies'));
  if (cookiesStoredInLocalStorage) {
    data.__d3__cookies = cookiesStoredInLocalStorage;
  } else {
    data.__d3__cookies = {};
  }
  return fetch(`${SupistaMISSettingsAPI}/${endpoint}`, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
    },
    credentials: 'include',
    body: JSON.stringify(data),
  })
    .then((response) => response.json())
    .then((response) => {
      if (response.hasOwnProperty('__d3__cookies')) {
        localStorage.setItem('__d3__cookies', JSON.stringify(response.__d3__cookies));
      }
      return response;
    })
    .catch((error) => {
      console.error('Error:', error);
      return {};
      // Handle any errors that occurred during the file upload
    });
};

export const _supistaApiDelete = async (endpoint, data) => {
  const cookiesStoredInLocalStorage = JSON.parse(localStorage.getItem('__d3__cookies'));
  if (cookiesStoredInLocalStorage) {
    data.__d3__cookies = cookiesStoredInLocalStorage;
  } else {
    data.__d3__cookies = {};
  }
  return fetch(`${SupistaMISSettingsAPI}/${endpoint}`, {
    method: 'DELETE',
    headers: {
      'Content-Type': 'application/json',
    },
    credentials: 'include',
    body: JSON.stringify(data),
  })
    .then((response) => response.json())
    .then((response) => {
      if (response.hasOwnProperty('__d3__cookies')) {
        localStorage.setItem('__d3__cookies', JSON.stringify(response.__d3__cookies));
      }
      return response;
    })
    .catch((error) => {
      console.error('Error:', error);
      return {};
      // Handle any errors that occurred during the file upload
    });
};

export function _uploadFileToServer(endpoint, formData) {
  return fetch(`${analyticsBaseAPI}/${endpoint}`, {
    method: 'POST',
    headers: {
      Accept: '*/*',
    },
    credentials: 'include',
    body: formData,
  })
    .then((response) => {
      if (!response.ok) {
        throw new Error('File upload failed.');
      }
      return response.json();
    })
    .catch((error) => {
      console.error('Error:', error);
      // Handle any errors that occurred during the file upload
    });
}
export function _supistaUploadFileToServer(endpoint, formData) {
  return fetch(`${SupistaMISSettingsAPI}/${endpoint}`, {
    method: 'POST',
    headers: {
      Accept: '*/*',
    },
    credentials: 'include',
    body: formData,
  })
    .then((response) => {
      if (!response.ok) {
        throw new Error('File upload failed.');
      }
      return response.json();
    })
    .catch((error) => {
      console.error('Error:', error);
      // Handle any errors that occurred during the file upload
    });
}

export async function _fetchFile(params, url) {
  const serverURL = `${analyticsBaseAPI}/${url}`;
  return (
    fetch(serverURL, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Accept:
          'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/json',
      },
      body: JSON.stringify(params),
      credentials: 'include',
    })
      // .then((response) => response.json())
      .then((response) => {
        if (!response.__d3__error) return response.blob();
        else return response.json();
      })
      .catch((error) => {
        console.error('Error:', error);
        return {};
        // Handle any errors that occurred during the file upload
      })
  );
}

export const supistaAuthApiPost = async (endpoint, data, method = 'POST', isUploadFile) => {
  const url = new URL(`${SupistaMISSettingsAPI}/${endpoint}`);
  const lang =
    localStorage.getItem('__d3__language') ||
    localStorage.getItem('__d3__defaultLanguage') ||
    'English';
  if (method === 'GET') {
    url.searchParams.append('data', JSON.stringify(data));
    url.searchParams.append('lang', lang);
  }
  const dataToSend = { ...data, lang };

  const fetchOptions = isUploadFile
    ? {
        method,
        headers: {
          Accept: '*/*',
        },
        credentials: 'include',
        body: data,
      }
    : {
        method,
        headers: {
          'Content-Type': 'application/json',
        },
        credentials: 'include',
        body: method !== 'GET' ? JSON.stringify(dataToSend) : undefined,
      };
  return fetch(url, fetchOptions).then(async (response) => {
    if (
      response.headers.get('Content-Type').includes('application/pdf') ||
      response.headers.get('Content-Type').includes('application/zip')
    ) {
      const contentDisposition = response.headers.get('Content-Disposition');
      // Set a default filename based on the content type
      let filename = response.headers.get('Content-Type').includes('application/pdf')
        ? 'generated-file.pdf'
        : 'generated-file.zip';

      // Extract the filename from the Content-Disposition header
      if (contentDisposition && contentDisposition.includes('attachment')) {
        const matches = /filename="?([^"]+)"?/.exec(contentDisposition);
        if (matches && matches[1]) {
          filename = matches[1];
        }
      }
      // Fetch the file as a blob
      const fileBlob = await response.blob();
      // Trigger the download
      const link = document.createElement('a');
      link.href = URL.createObjectURL(fileBlob);
      link.download = filename; // Use the filename from the server or the default one
      link.click();
      return null; // Returning null as the download is triggered
    }
    const response2 = response.clone();
    try {
      const jsonRes = await response.json();
      return jsonRes;
    } catch (err) {
      console.error(err, 'catched error');
      return response2.text();
    }
  });
};

export const getURL = (endpoint, data) => {
  const url = new URL(`${SupistaMISSettingsAPI}/${endpoint}`);
  const lang =
    localStorage.getItem('__d3__language') ||
    localStorage.getItem('__d3__defaultLanguage') ||
    'English';
  url.searchParams.append('data', JSON.stringify(data));
  url.searchParams.append('lang', lang);
  return url;
};
