import React, { useMemo, useState } from 'react';
import { mls } from 'lib/multilanguagesupport';
import style from './roleAccess.module.scss';
import ObjectDropDown from 'lib/reusable-components/Components/DropDowns/ObjectDropDown';
import RoleAccessAddTeamsPopup from '../../RoleAccessAddTeamsPopup/RoleAccessAddTeamsPopup';
import RoleAccessTeamCrudContainer from './RoleAccessTeamCrudContainer';
import ColumnWiseRolesContainer from './ColumnWiseRolesContainer';
import RoleComponentFilter from '../../RoleComponentFilter/RoleComponentFilter';
import { filterTypeObject } from '../../data/appComponentData';
import {
  getAppComponentFilterColumnSchema,
  getFinalTableID,
  getGroupOptionData,
} from '../../utils/componentSettingFunction';
import { relationNestedLevel } from 'lib/erp-data-table/components/util/componentUpdateFunction';

interface roleAccessProps {
  connectedTablesData: any;
  componentSchema: { [key: string]: any };
  appSchema: { [key: string]: any };
  groupedConnectedTablesData: { [key: string]: any };
  setComponentSchema: Function;
}
const RoleAccess = ({
  connectedTablesData,
  componentSchema,
  setComponentSchema,
  appSchema,
  groupedConnectedTablesData,
}: roleAccessProps) => {
  const newConnectedTable = useMemo(() => {
    let updatedConnectedTable = {};
    Object.keys(connectedTablesData ?? {}).forEach((conectedTableKey: string) => {
      const conectedTableElem = connectedTablesData[conectedTableKey];
      const updatedConnectedTableElem = {
        tableID: conectedTableElem?.tableID,
        name: `${conectedTableElem?.name} ( ${conectedTableElem?.relationType} )`,
        relationType: conectedTableElem?.relationType,
      };
      updatedConnectedTable = {
        ...updatedConnectedTable,
        [conectedTableKey]: updatedConnectedTableElem,
      };
    });

    return updatedConnectedTable;
  }, [connectedTablesData]);
  // const connectedTablesDataForSelectTable = useMemo(() => {
  //   let updatedConnectedTable = {};
  //   (componentSchema?.tableList ?? []).forEach((tableListElem: any) => {
  //     const finalTableID = getFinalTableID({
  //       tableID: tableListElem?.tableID,
  //       relationType: tableListElem?.relationType,
  //       relationID: tableListElem?.relationID,
  //     });
  //     if (connectedTablesData?.[finalTableID]) {
  //       const conectedTableElem = connectedTablesData?.[finalTableID];
  //       const updatedConnectedTableElem = {
  //         tableID: conectedTableElem?.tableID,
  //         name: `${conectedTableElem?.name} ( ${conectedTableElem?.relationType} )`,
  //         relationType: conectedTableElem?.relationType,
  //       };
  //       updatedConnectedTable = {
  //         ...updatedConnectedTable,
  //         [finalTableID]: updatedConnectedTableElem,
  //       };
  //     }
  //   });
  //   return updatedConnectedTable;
  // }, [connectedTablesData, componentSchema?.tableList]);
  const connectedTablesDataForSelectTable = useMemo(() => {
    let selectedConnectedTableData = {};
    (componentSchema?.tableList ?? []).forEach((tableListElem: any) => {
      const finalTableID = getFinalTableID({
        tableID: tableListElem?.tableID,
        relationType: tableListElem?.relationType,
        relationID: tableListElem?.relationID,
      });
      if (connectedTablesData?.[finalTableID]) {
        selectedConnectedTableData = {
          ...selectedConnectedTableData,
          [finalTableID]: connectedTablesData?.[finalTableID] ?? {},
        };
      }
    });
    return selectedConnectedTableData;
  }, [connectedTablesData, componentSchema?.tableList]);
  const { groupOptionData, updatedConnectedTablesData } = useMemo(() => {
    const { groupOptionData, updatedConnectedTablesData } = getGroupOptionData({
      connectedTablesData: connectedTablesDataForSelectTable,
      nestedLevel: 3,
    });
    return { groupOptionData: groupOptionData, updatedConnectedTablesData };
  }, [connectedTablesDataForSelectTable]);

  const [selectedTable, setSelectedTable] = useState(
    Object.keys(newConnectedTable ?? {})?.[0] ?? ''
  );
  const selectedTableData =
    updatedConnectedTablesData?.[selectedTable as keyof typeof updatedConnectedTablesData] ?? {};

  const roleAccessSchema = componentSchema?.roleAccess?.[selectedTable] ?? {};

  const setRoleAccessSchema = (roleAccessData: any) => {
    setComponentSchema({
      ...componentSchema,
      roleAccess: { ...(componentSchema?.roleAccess ?? {}), [selectedTable]: roleAccessData },
    });
  };

  const appTeams: { [key: string]: any } = appSchema?.appErpTeams ?? {};

  const [isDisplayToPopup, setIsDisplayToPopup] = useState(false);
  const [selectedTeamForColumn, setSelectedTeamForColumn] = useState('');
  const [selectedTeams, setSelectedTeams] = useState(Object.keys(roleAccessSchema ?? {}));
  const handleDisplayToPopup = () => {
    setIsDisplayToPopup(!isDisplayToPopup);
  };
  const handleSelectedTeamForColumn = (selectedTeamForColumnKey: string) => {
    setSelectedTeamForColumn(selectedTeamForColumnKey);
  };
  const selectedTeamsObject = useMemo(() => {
    const appTeams: { [key: string]: any } = appSchema?.appErpTeams ?? {};

    let selectedTeamElemObject: { [key: string]: any } = {};
    (selectedTeams ?? []).forEach((selectedTeamsKey: string) => {
      const selectedTeamElem = appTeams[selectedTeamsKey];
      if (selectedTeamElem) {
        selectedTeamElemObject = {
          ...selectedTeamElemObject,
          [selectedTeamsKey]: selectedTeamElem,
        };
      }
    });
    if (!selectedTeamElemObject[selectedTeamForColumn]) {
      handleSelectedTeamForColumn(Object.keys(selectedTeamElemObject ?? {})?.[0] ?? '');
    }
    return selectedTeamElemObject;
  }, [selectedTeams, appSchema?.appErpTeams, selectedTeamForColumn]);
  const [filterData, setFilterData] = useState({
    popupStatus: false,
    filters: {},
    selectedTeamsKey: '',
    key: '',
    filterType: '',
    uniqueColumnName: '',
    specialCase: false,
  });

  const handleFilterPopupStatus = () => {
    const teamKey = filterData?.selectedTeamsKey;
    const filters = filterData?.filters;
    const key = filterData?.key;
    const filterType = filterData?.filterType;
    const uniqueColumnName = filterData?.uniqueColumnName;
    if (filterType === filterTypeObject.teamFilter.key) {
      let finalTeamAccess: { [key: string]: any } = { ...roleAccessSchema?.[teamKey] };
      finalTeamAccess = {
        ...finalTeamAccess,
        [key]: { ...(finalTeamAccess?.[key] ?? {}), componentFilter: filters ?? {} },
      };
      setRoleAccessSchema({ ...roleAccessSchema, [teamKey]: finalTeamAccess });
    }
    if (filterType === filterTypeObject.columnFilter.key) {
      let finalTeamAccess: { [key: string]: any } = { ...roleAccessSchema?.[teamKey] };
      finalTeamAccess = {
        ...finalTeamAccess,
        [key]: {
          ...(finalTeamAccess?.[key] ?? {}),
          columnFilter: {
            ...(finalTeamAccess?.[key]?.columnFilter ?? {}),
            [uniqueColumnName]: filters ?? {},
          },
        },
      };

      setRoleAccessSchema({ ...roleAccessSchema, [teamKey]: finalTeamAccess });
    }
    setFilterData({ ...filterData, popupStatus: !filterData.popupStatus, specialCase: false });
  };
  const handleFilters = (action: any) => {
    setFilterData({ ...filterData, filters: action });
  };

  // const columnSchema = useMemo(() => {
  //   return getAppComponentColumnSchema({
  //     connectedTableList: componentSchema?.connectedTableList ?? [],
  //     connectedTablesData: connectedTablesData,
  //   });
  // }, [componentSchema?.connectedTableList, connectedTablesData]);
  const columnSchema = useMemo(() => {
    const finalColumns = getAppComponentFilterColumnSchema({
      connectedTablesData: updatedConnectedTablesData,
      tableData: selectedTableData,
      finalTableID: selectedTable,
      groupedConnectedTablesData: connectedTablesData,
    });
    return finalColumns;
  }, [connectedTablesData, selectedTableData, selectedTable, updatedConnectedTablesData]);

  const handleTableSelection = (action: string) => {
    setSelectedTable(action);
  };

  return (
    <div className={style.roleAccessContainer}>
      <div>
        {isDisplayToPopup ? (
          <RoleAccessAddTeamsPopup
            isDisplayToPopup={isDisplayToPopup}
            setIsDisplayToPopup={setIsDisplayToPopup}
            selectedTeams={selectedTeams}
            setSelectedTeams={setSelectedTeams}
            roleAccessSchema={roleAccessSchema}
            setRoleAccessSchema={setRoleAccessSchema}
            selectedTableData={selectedTableData}
            componentSchema={componentSchema}
            setComponentSchema={setComponentSchema}
            newConnectedTable={updatedConnectedTablesData}
            connectedTablesData={updatedConnectedTablesData}
            // newConnectedTable={newConnectedTable}
            // connectedTablesData={connectedTablesData}
          />
        ) : null}
        {filterData.popupStatus ? (
          <RoleComponentFilter
            setChartFilter={handleFilters}
            chartFilter={filterData?.filters ?? {}}
            allTableData={columnSchema}
            appID={''}
            sheetID={''}
            isJoinedTable={false}
            isUserLevelAccess={true}
            specialCase={filterData.specialCase}
            isPopUp={filterData.popupStatus}
            setIsPopUp={handleFilterPopupStatus}
          />
        ) : null}
      </div>
      <div className={style.columnWiseRoles}>
        <div className={style.columnWiseRolesTitle}>{mls('Select Table')}</div>
        <div className={style.selectedRolesDropDownContainer}>
          {/* <ObjectDropDown
            object={connectedTablesDataForSelectTable}
            dropDownValue={selectedTable}
            mainKey='tableID'
            setdropDownValue={handleTableSelection}
            ObjectInObject
            inputPlaceHolder={mls('Select Table')}
          /> */}
          <ObjectDropDown
            object={groupOptionData}
            dropDownValue={selectedTable}
            setdropDownValue={handleTableSelection}
            groupDropDown
            groupDropDownType={'groupAndOptionSelectable'}
            groupDropDownNestingLevel={relationNestedLevel - 2}
            inputPlaceHolder={mls('Select Table')}
            search
            className='ComponentSelectTableDropdown'
          />
        </div>
      </div>

      <div className={style.roleAccessInnerContainer}>
        <div className={style.roleAccessTeamSelection}>
          {mls('Allow Teams to give Access')}
          <button
            className='primaryButton secondaryButtonColor'
            onClick={() => handleDisplayToPopup()}
          >
            {mls('Select Teams')}
          </button>
        </div>
        <RoleAccessTeamCrudContainer
          appTeams={appTeams}
          roleAccessSchema={roleAccessSchema}
          setRoleAccessSchema={setRoleAccessSchema}
          setFilterData={setFilterData}
          filterData={filterData}
          selectedTableData={selectedTableData}
        />
        <div className={style.columnWiseRoles}>
          <div className={style.columnWiseRolesTitle}>{mls('Column wise roles for each team')}</div>
          <div className={style.selectedRolesDropDownContainer}>
            <ObjectDropDown
              object={selectedTeamsObject}
              dropDownValue={selectedTeamForColumn}
              mainKey='teamID'
              setdropDownValue={handleSelectedTeamForColumn}
              ObjectInObject
              inputPlaceHolder={mls('Select Teams')}
            />
          </div>
        </div>
        <ColumnWiseRolesContainer
          // connectedTablesData={connectedTablesData}
          connectedTablesData={updatedConnectedTablesData}
          selectedTeamForColumn={selectedTeamForColumn}
          roleAccessSchema={roleAccessSchema}
          setRoleAccessSchema={setRoleAccessSchema}
          setFilterData={setFilterData}
          filterData={filterData}
          selectedTableData={selectedTableData}
        />
      </div>
    </div>
  );
};

export default RoleAccess;
