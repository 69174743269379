import React from 'react';
import styles from './templatesWrapper.module.scss';
import { mls } from 'lib/multilanguagesupport';
import CodeEditor from 'lib/reusable-components/Components/CodeEditor/CodeEditor';

const CodeContainer = ({
  sampleData,
  htmlContent,
  codeContainerTypes,
  codeContainerType,
  handelContainetChange,
  outputData,
  inputData,
  setInputData,
  templateData,
  isCodeDataChange,
  setIsCodeDataChange,
  setCodeContainerType,
}) => {
  return (
    <div className={styles.codeContainer}>
      <div
        style={
          codeContainerType === codeContainerTypes.code.key
            ? { height: '100%' }
            : { display: 'none', height: '100%' }
        }
      >
        <AddCodeMannually
          setIsCodeDataChange={setIsCodeDataChange}
          isShowAddCode={!templateData?.code?.functionCode && isCodeDataChange}
        >
          <CodeEditor
            height='100%'
            defaultLanguage='javascript'
            value={inputData}
            onChange={(value) => setInputData(value ?? '')}
            theme='vs-dark'
          />
        </AddCodeMannually>
      </div>
      <div
        style={
          codeContainerType === codeContainerTypes.output.key
            ? { height: '100%' }
            : { display: 'none', height: '100%' }
        }
      >
        <AddCodeMannually
          setIsCodeDataChange={() => {
            setIsCodeDataChange(false);
            setCodeContainerType(codeContainerTypes.code.key);
          }}
          isShowAddCode={!templateData?.code?.functionCode && isCodeDataChange}
        >
          <CodeEditor
            height='100%'
            defaultLanguage='json'
            value={JSON.stringify(outputData?.msg ?? '')}
            onChange={() => {}}
            theme='vs-dark'
            readOnly={true}
          />
        </AddCodeMannually>
      </div>
      <div
        style={
          codeContainerType === codeContainerTypes.html.key
            ? { height: '100%' }
            : { display: 'none', height: '100%' }
        }
      >
        <AddCodeMannually
          setIsCodeDataChange={setIsCodeDataChange}
          isShowAddCode={!templateData?.htmlString && isCodeDataChange}
        >
          <CodeEditor
            height='100%'
            defaultLanguage='handlebars'
            value={htmlContent}
            onChange={(value) => handelContainetChange(value ?? '')}
            theme='vs-dark'
          />
        </AddCodeMannually>
      </div>

      <div
        style={
          codeContainerType === codeContainerTypes.data.key
            ? { height: '100%' }
            : { display: 'none', height: '100%' }
        }
      >
        <CodeEditor
          height='100%'
          defaultLanguage='json'
          value={JSON.stringify(sampleData ?? '')}
          onChange={() => {}}
          readOnly={true}
          autoFormatTriggerCharacters={JSON.stringify(sampleData ?? '')}
        />
      </div>
    </div>
  );
};

export default CodeContainer;

const AddCodeMannually = ({ children, setIsCodeDataChange, isShowAddCode }) => {
  return (
    <>
      {isShowAddCode ? (
        <div
          style={{
            height: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            fontSize: '16px',
            gap: '1rem',
            flexDirection: 'column',
            border: '1px solid #e0e0e0',
            borderRadius: '6px',
          }}
        >
          <div> {mls('Do you want to add code?')}</div>
          <button
            type='button'
            className='primaryButton secondaryButtonColor'
            onClick={() => setIsCodeDataChange(false)}
          >
            {mls('Add code')}
          </button>
        </div>
      ) : (
        children
      )}
    </>
  );
};
