// import { supistaApiPost } from 'lib/data-connectors/dependencies/connections';
import { useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { checkIDField, columnTypeChecker } from '../helper/columnTypeChecker';
import reduxConstants from 'lib/reusable-components/reusableUtils/redux/reduxConstants';
import { create } from 'lib/reusable-components/reusableUtils/redux/stateSlice';
import store from 'lib/reusable-components/reusableUtils/redux/store';

// import useUpdateTableSchema from 'lib/data-connectors/dependencies/useUpdateTableSchema';
import { useSelector } from 'react-redux';

import { mls } from 'lib/multilanguagesupport';
import useUpdateTableSchema from '../../../hooks/useUpdateTableSchema';
import {
  charactersNotAllowedInTableAndColumnName,
  convertToTitleCase,
  finalTableUpdate,
  renameKeysInArrayOfObjects,
} from '../../../components/util/utilFunctions';
import { _supistaApiPost } from 'lib/server-connection/connections';
import { publishToProduction } from '../../../components/util/publishToProduction';
import NewDataTableSetting from './NewDataTableSetting';
import { toast } from 'react-toastify';
// import { publishToProduction } from 'lib/reusable-components/reusableFunction/publishToProductions';

// import { charactersNotAllowedInTableAndColumnName } from 'lib/data-connectors/dependencies/constant';
// import {
//   convertColumnDataType,
//   removeSpacesFromKeys,
//   renameKeysInArrayOfObjects,
// } from 'lib/data-connectors/dependencies/utilFunctions';
// import { publishToProduction } from 'lib/data-connectors/dependencies/publishToProductions';

const maxCharactersAllowed = 30;
export const CreateNewDataTableSettings = ({
  csvData,
  setCsvData,
  filename = '',
  setTableSchema = () => {},
  setActiveModalPage = () => {},
  isManual = false,
  setFilename,
  setDataTableData,
  setOpen,
  allDataTableData,
}) => {
  const param = useParams();
  const appID = param.appID;
  const [tableNameError, setTableNameError] = useState(false);
  const [tableName, setTableName] = useState(filename?.split('.csv')[0] || '');
  const [invalidColumnNameIndices] = useState(new Set());
  const appDatatable = useSelector((state) => state.config_store.appSchema.appDatatable);
  const handleTableNameError = (tableName) => {
    const isTableNameLenghtLessThenLimit = tableName.length > maxCharactersAllowed;
    if (isTableNameLenghtLessThenLimit) {
      setTableNameError('Max 30 characters allowed');
      return { error: true };
    } else {
      setTableNameError(false);
      return { error: false };
    }
  };
  const handleTableName = (tableName) => {
    const tableNameError = handleTableNameError(tableName);
    if (!tableNameError.error) {
      setTableName(tableName);
    }
  };
  useEffect(() => {
    const containsCharacterWhichIsNotAllowed = charactersNotAllowedInTableAndColumnName.some(
      (char) => {
        return tableName.includes(char);
      }
    );
    const tableWithThisNameAlreadyExists = appDatatable?.[tableName] ? true : false;
    const isTableNameLenghtLessThenLimit = tableName.length > maxCharactersAllowed;
    tableWithThisNameAlreadyExists && setTableNameError('Table name must be unique');
    containsCharacterWhichIsNotAllowed &&
      setTableNameError(
        "Table name can only contain these special characters: space (' '), hyphen (-) and underscore (_)"
      );
    isTableNameLenghtLessThenLimit && setTableNameError('Max 30 characters allowed');
    !containsCharacterWhichIsNotAllowed &&
      !tableWithThisNameAlreadyExists &&
      !isTableNameLenghtLessThenLimit &&
      setTableNameError(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tableName]);

  const updateTableSchema = useUpdateTableSchema();

  const columnData = useMemo(() => {
    return columnTypeChecker(csvData[0] ?? {});
  }, [csvData]);
  const originalDataTableSchema = {
    version: 2.0,
    appID: appID,
    tableID: tableName,
    name: tableName,
    relations: {},
    columnSchema: columnData,
    updates: {
      addColumns: [],
      updateColumns: [],
      deleteColumns: [],
      renameColumns: {},
      dropTable: false,
      createTable: true,
    },
    createdAt: Date.now(),
    updatedAt: Date.now(),
  };
  const [dataTableSchema, setDataTableSchema] = useState(originalDataTableSchema);
  const [tableUpdates, setTableUpdates] = useState({
    addColumns: [],
    updateColumns: [],
    deleteColumns: [],
    renameColumns: {},
    dropTable: false,
    createTable: true,
  });
  const [relatedTableList, setRelatedTableList] = useState({});
  const [isTableNameConfirmed, setisTableNameConfirmed] = useState(false);

  const save = () => {
    const currentTimestamp = Date.now();
    // const udpatedTableUpdate = finalTableUpdate({
    //   tableUpdates,
    //   originalDataTableSchema: originalDataTableSchema,
    // });

    const finalDataTableSchema = {
      ...dataTableSchema,
      appID: appID,
      version: 2.0,
      // updates: udpatedTableUpdate,
      createdAt: currentTimestamp,
      updatedAt: currentTimestamp,
    };
    _supistaApiPost(`settings/${appID}/DataTable`, {
      data: finalDataTableSchema,
    })
      .then((response) => {
        if (!response?.__d3__success) {
          toast.error('Not able to save please try again later!');
          return;
        }
        // console.log(response);
        // console.log(csvData, 'csvData');
        // If manual table creation, display success message immediately after creating the table
        if (isManual) {
          store.dispatch(
            create({
              setPath: reduxConstants.config.APP_STATUS.TOAST,
              value: {
                message: 'Saved successfully',
                time: 5,
                isActive: true,
              },
            })
          );
        }
        setTimeout(() => {
          // Sending the csv rows data to backend only if CSV upload is done
          if (!isManual) {
            // Size of chunk of rows' data to be sent at once
            const CHUNK_SIZE = 1000;
            const csvDataKeysSpacesRemoved = csvData;
            // var success = true;
            const rowsDataUploadPromises = [];
            for (let i = 0; i < csvDataKeysSpacesRemoved.length; i += CHUNK_SIZE) {
              const rowsData = csvDataKeysSpacesRemoved.slice(i, i + CHUNK_SIZE);
              rowsDataUploadPromises.push(
                _supistaApiPost(`app/${appID}/TABLE_SOURCE/${tableName}/uploadCSV`, {
                  data: {
                    __d3__csvData: rowsData,
                  },
                })
              );
            }

            Promise.all(rowsDataUploadPromises)
              .then((csvDataUploadResponses) => {
                let success = false;
                for (let i = 0; i < csvDataUploadResponses.length; i++) {
                  const oneChunkRowUploadResponse = csvDataUploadResponses[i];
                  if (oneChunkRowUploadResponse.bulkCreated) {
                    success = true;
                  } else {
                    success = false;
                  }
                  if (success === false) throw new Error('Rows not uploaded');
                }
                publishToProduction(appID);
              })
              .catch((error) => {
                store.dispatch(
                  create({
                    setPath: reduxConstants.config.APP_STATUS.ERROR_TOAST,
                    value: {
                      message:
                        'Table created but rows cannot be uploaded. Check the column data types properly.',
                      time: 5,
                      isActive: true,
                    },
                  })
                );
              });
          }
        }, 100);
        Object.values(relatedTableList || {}).forEach((relatedTable) => {
          const sendRelatedData = {
            ...relatedTable,
            // updates: columnUpdates,
            updatedAt: currentTimestamp,
          };
          _supistaApiPost(`settings/${appID}/DataTable`, {
            data: sendRelatedData,
          });
          updateTableSchema(
            false,
            null,
            sendRelatedData,
            sendRelatedData.tableID,
            sendRelatedData.name
          );
        });
        // Update the local redux
        updateTableSchema(false, null, finalDataTableSchema, tableName);
        publishToProduction(appID);
      })
      .catch((err) => {
        // console.log(err);
      });
    setOpen(false);
    publishToProduction(appID);
  };

  return (
    <div className='d-flex flex-column justify-content-center align-items-center p-5'>
      <h1 className='mb-5 mt-10'>{isManual ? 'Create Table' : 'CSV Data'}</h1>
      {isTableNameConfirmed ? (
        <>
          <div style={{ width: '80vw' }}>
            <NewDataTableSetting
              dataTableSchema={dataTableSchema}
              setDataTableSchema={setDataTableSchema}
              tableUpdates={tableUpdates}
              setTableUpdates={setTableUpdates}
              allDataTableData={allDataTableData}
              relatedTableList={relatedTableList}
              setRelatedTableList={setRelatedTableList}
            />
          </div>
          <div
            style={{
              position: 'sticky',
              bottom: '-16px',
            }}
            className='d-flex justify-content-end w-100 py-5 px-5 bg-white'
          >
            <div className='me-4'>
              <button
                href='#'
                className='btn btn-sm btn-flex btn-light btn-active-primary fw-bolder'
                data-bs-dismiss='modal'
                onClick={() => setOpen(false)}
              >
                {mls('Cancel')}
              </button>
            </div>
            <button
              href='#'
              className='btn btn-sm btn-primary'
              disabled={
                tableName.trim() === '' || tableNameError || invalidColumnNameIndices.size > 0
              }
              onClick={save}
            >
              {mls('Save Table')}
            </button>
          </div>
        </>
      ) : (
        <>
          <div style={{ width: '80vw' }}>
            <label className='col-md-5 fs-5 fw-bold mb-2'>{mls('Table Name')}</label>
            <input
              type='text'
              className='checkbox form-control form-control-solid'
              placeholder='Table Name'
              value={tableName}
              onBlur={() => handleTableNameError(tableName)}
              onChange={(e) => handleTableName(e.target.value)}
            />
            {tableNameError && (
              <div className='fv-plugins-message-container'>
                <span className='text-danger' role='alert'>
                  {tableNameError}
                </span>
              </div>
            )}
          </div>
          <div
            style={{
              position: 'sticky',
              bottom: '-16px',
            }}
            className='d-flex justify-content-end w-100 py-5 px-5 bg-white'
          >
            <div className='me-4'>
              <button
                href='#'
                className='btn btn-sm btn-flex btn-light btn-active-primary fw-bolder'
                data-bs-dismiss='modal'
                onClick={() => setOpen(false)}
              >
                {mls('Cancel')}
              </button>
            </div>
            <button
              href='#'
              className='btn btn-sm btn-primary'
              disabled={tableName.trim() === '' || tableNameError}
              onClick={() => {
                setisTableNameConfirmed(true);
                setDataTableSchema({ ...dataTableSchema, tableID: tableName, name: tableName });
              }}
            >
              {mls('Next')}
            </button>
          </div>
        </>
      )}
    </div>
  );
};
