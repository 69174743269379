import React, { useState, useRef } from 'react';
import Editor from '@monaco-editor/react';
import { Play } from 'react-bootstrap-icons';
import styles from './codeCustomization.module.scss';
import { mls } from 'lib/multilanguagesupport';
import { v4 as uuid } from 'uuid';
import { CircularProgress } from '@mui/material';
import { _supistaApiPost } from 'lib/server-connection/connections';
import useDivDimensions from 'lib/reusable-components/hooks/divDimensionHelper';
import {
  buttonTypeObject,
  codeContainerTypes,
  crudTypeButtonObject,
  defaultCode,
  outputTypeObject,
} from '../../data/appComponentData';
import { getDefaultCode } from '../../utils/componentSettingFunction';
import { toast } from 'react-toastify';
import { MdOutlineKeyboardDoubleArrowDown } from 'react-icons/md';
import ConfirmReset from './ConfirmReset';
import ReadOnlyCodeEditor from 'lib/reusable-components/Components/CodeEditor/ReadOnlyCodeEditor';
import CodeEditor from 'lib/reusable-components/Components/CodeEditor/CodeEditor';
import { defaultTimeInterval } from './TimeInterval/timeIntervalData';
interface queryContainerProps {
  componentSchema: any;
  activeButtonKey: string;
  title: string;
  selectedCode: { [key: string]: any };
  selectedTable: string;
  updateCodeCustomization: Function;
  buttonType: string;
  codeContainerType: string;
  setCodeContainerType: Function;
  outputData: any;
  code: any;
  handleCodeChange: any;
  inputData: any;
  setInputData: Function;
  setOutputData: Function;
  setIsInputChanged: Function;
  appID: any;
  sampleData: any;
  setCode: Function;
  setButtonType: Function;
  setActiveButtonKey: Function;
  isMoreOptionsOpen: boolean;
  setIsMoreOptionsOpen: Function;
}
const QueryContainer = ({
  componentSchema,
  activeButtonKey,
  title,
  selectedCode,
  selectedTable,
  updateCodeCustomization,
  buttonType,
  codeContainerType,
  setCodeContainerType,
  outputData,
  setOutputData,
  code,
  handleCodeChange,
  inputData,
  setInputData,
  appID,
  sampleData,
  setIsInputChanged,
  setCode,
  isMoreOptionsOpen,
  setIsMoreOptionsOpen,
}: queryContainerProps) => {
  const selectedButton = buttonTypeObject[buttonType as keyof typeof buttonTypeObject];
  const selectedButtonName =
    selectedButton.key === buttonTypeObject.CRUD.key ? activeButtonKey : selectedButton?.name;
  const codeContainerRef = useRef(null);
  const { height: codeContainerHeight } = useDivDimensions(codeContainerRef);
  const [isCodeTestLoading, setIsCodeTestLoading] = useState(false);

  const handleTestCode = async () => {
    const componentID = componentSchema?.componentID;
    const baseUrl = `app/${appID}/TestCustomCode/${componentID}`;
    const { selectedData, codePresent } = getDefaultCode({
      componentSchema,
      selectedTable,
      actionButtonKey: activeButtonKey,
      buttonType,
    });

    const functionID = selectedData?.functionID ?? uuid();
    let parsedInput = {};
    try {
      parsedInput = JSON.parse(inputData ?? '');
    } catch (error) {
      toast.error(`Error in Input:
        ${error}`);
      return;
    }

    const data = {
      functionCode: code,
      functionID: functionID,
      functionName: 'customizeERP',
      inputs: parsedInput ?? {},
      isFormCustomization: activeButtonKey === buttonTypeObject.formValidation.key ? true : false,
    };
    setIsCodeTestLoading(true);
    const componentRes = await _supistaApiPost(baseUrl, { data });
    if (componentRes?.__d3__success) {
      const isCodeError = componentRes?.response?.codeResponse?.__d3__error;
      let finalOutput = { ...outputData };
      if (isCodeError) {
        finalOutput = {
          ...finalOutput,
          msg: { response: componentRes?.response?.codeResponse ?? {} },
          outputType: outputTypeObject.error,
        };
        setIsCodeTestLoading(false);
        setOutputData(finalOutput);
        setCodeContainerType(codeContainerTypes.output.key);
        return;
      }
      if (buttonType === buttonTypeObject.CRUD.key) {
        const updatedCrudFunction = {
          fuctionName: 'displayFunc',
          functionID: functionID,
          functionCode: code,
          params: [],
          type: 'CUSTOM',
        };
        updateCodeCustomization({
          selectedTable,
          codeCustomizationData: {
            ...selectedCode,
            crudOp: { ...(selectedCode?.crudOp ?? {}), [activeButtonKey]: updatedCrudFunction },
          },
        });
      } else if (buttonType === buttonTypeObject.formValidation.key) {
        const updatedCrudFunction = {
          fuctionName: 'displayFunc',
          functionID: functionID,
          functionCode: code,
          params: [],
          type: 'CUSTOM',
        };
        updateCodeCustomization({
          selectedTable,
          codeCustomizationData: {
            ...selectedCode,
            formValidation: updatedCrudFunction,
          },
        });
      } else if (buttonType === buttonTypeObject.actionButton.key) {
        const updatedCrudFunction = {
          fuctionName: 'actionFunc',
          functionID: functionID,
          functionCode: code,
          displayWhen: selectedCode?.actionButton?.[activeButtonKey]?.displayWhen ?? [],
          displayTo: selectedCode?.actionButton?.[activeButtonKey]?.displayTo ?? [],
          title: title,
        };
        updateCodeCustomization({
          selectedTable,
          codeCustomizationData: {
            ...selectedCode,
            actionButton: {
              ...(selectedCode?.actionButton ?? {}),
              [activeButtonKey]: updatedCrudFunction,
            },
          },
        });
      } else if (buttonType === buttonTypeObject.cronJob.key) {
        const updatedCrudFunction = {
          fuctionName: 'actionFunc',
          functionID: functionID,
          functionCode: code,
          displayWhen: selectedCode?.actionButton?.[activeButtonKey]?.displayWhen ?? [],
          displayTo: selectedCode?.actionButton?.[activeButtonKey]?.displayTo ?? [],
          title: title,
          scheduler:
            selectedCode?.actionButton?.[activeButtonKey]?.scheduler ?? defaultTimeInterval,
        };
        updateCodeCustomization({
          selectedTable,
          codeCustomizationData: {
            ...selectedCode,
            cronJob: {
              ...(selectedCode?.cronJob ?? {}),
              [activeButtonKey]: updatedCrudFunction,
            },
          },
        });
      }

      finalOutput = {
        ...finalOutput,
        msg: { response: componentRes?.response?.codeResponse ?? {} },
        outputType: outputTypeObject.success,
      };
      setOutputData(finalOutput);
      setCodeContainerType(codeContainerTypes.output.key);
      setIsCodeTestLoading(false);
    } else {
      setIsCodeTestLoading(false);
    }
  };

  const [confirmResetModal, setConfirmResetModal] = useState(false);
  const handleResetCode = () => {
    if (buttonType === buttonTypeObject.CRUD.key) {
      const curdOp = { ...(selectedCode?.crudOp ?? {}) };
      delete curdOp[activeButtonKey];
      updateCodeCustomization({
        selectedTable,
        codeCustomizationData: {
          ...selectedCode,
          crudOp: curdOp,
        },
      });
    } else if (buttonType === buttonTypeObject.formValidation.key) {
      const updaedSelectedCode = { ...(selectedCode ?? {}) };
      delete updaedSelectedCode?.formValidation;
      updateCodeCustomization({
        selectedTable,
        codeCustomizationData: updaedSelectedCode,
      });
    } else if (buttonType === buttonTypeObject.actionButton.key) {
      // const actionButton = { ...(selectedCode?.actionButton ?? {}) };
      // delete actionButton[activeButtonKey];
      // updateCodeCustomization({
      //   selectedTable,
      //   codeCustomizationData: {
      //     ...selectedCode,
      //     actionButton: actionButton,
      //   },
      // });
      // setButtonType(buttonTypeObject.CRUD.key);
      // setActiveButtonKey(crudTypeButtonObject.GET.key);
    }
    const { selectedData } = getDefaultCode({
      componentSchema,
      selectedTable,
      actionButtonKey: activeButtonKey,
      buttonType,
    });
    setCode(selectedData?.functionCode ?? defaultCode);
    setConfirmResetModal(false);
  };
  return (
    <div
      className={`${styles.queryContainer} ${
        buttonType === buttonTypeObject.actionButton.key ||
        buttonType === buttonTypeObject.cronJob.key
          ? isMoreOptionsOpen
            ? styles.queryContainerWithPermission
            : ''
          : styles.queryContainerNoHeader
      }`}
    >
      <div className={styles.queryEditorTabOuterContainer}>
        <div className={styles.queryEditorTabHeaderContainer}>
          <div className={styles.queryEditorTabContainer}>
            {React.Children.toArray(
              Object.keys(codeContainerTypes).map((codeContainerTypeKey: string) => {
                const codeContainerTypeElem: any =
                  codeContainerTypes[codeContainerTypeKey as keyof typeof codeContainerTypes];
                return (
                  <div
                    className={`${styles.queryEditorTabOption} ${
                      codeContainerType === codeContainerTypeKey ? styles.activeContainerTab : ''
                    } ${
                      codeContainerTypeElem?.isLowerResolutionTab ? styles.lowerResolutionTab : ''
                    }`}
                    onClick={() => setCodeContainerType(codeContainerTypeElem.key)}
                  >
                    {mls(codeContainerTypeElem.name)}
                    {codeContainerTypeElem.key === codeContainerTypes.output.key ? (
                      <div
                        className={`${styles.outputColorIndicator} ${
                          styles?.[outputData?.outputType?.class ?? outputTypeObject?.normal?.class]
                        }`}
                      ></div>
                    ) : null}
                  </div>
                );
              })
            )}
          </div>
          <ConfirmReset
            confirmResetModal={confirmResetModal}
            setConfirmResetModal={setConfirmResetModal}
            handleReset={handleResetCode}
            buttonType={buttonType}
          />
          {/* 
          {buttonType === buttonTypeObject.actionButton.key ? (
            <div
              className={`${styles.moreOptionButton} ${styles.MarginLeftAuto}`}
              onClick={() => setIsMoreOptionsOpen(!isMoreOptionsOpen)}
            >
              {mls('More Options ')}
              <span
                className={` ${styles.moreOptionButtonArrow}  ${
                  isMoreOptionsOpen ? styles.moreOptionOpen : ''
                }`}
              >
                <MdOutlineKeyboardDoubleArrowDown />
              </span>
            </div>
          ) : null} */}

          <div
            // className={` ${styles.resetCodeButton} ${
            //   buttonType !== buttonTypeObject.actionButton.key ? styles.MarginLeftAuto : ''
            // }`}
            className={` ${styles.resetCodeButton} ${styles.MarginLeftAuto}`}
            onClick={() => setConfirmResetModal(true)}
          >
            {mls('Reset Code')}
          </div>
          <div className={` ${styles.runCodeButton}`} onClick={() => handleTestCode()}>
            {isCodeTestLoading ? (
              <>
                <CircularProgress color='inherit' size={10} /> &nbsp;
              </>
            ) : null}
            {mls('Run Code')}
            &nbsp;
            <Play />
          </div>
        </div>
        <div className={styles.queryEditorInnerContainer}>
          <div className={styles.queryEditorContainer}>
            <div className={styles.querytitleArea}>
              {codeContainerType === codeContainerTypes.code.key
                ? `${mls('Code')} ( ${selectedButtonName} )`
                : null}
              {codeContainerType === codeContainerTypes.output.key
                ? `${mls('Output')} ( ${selectedButtonName} )`
                : null}
              {codeContainerType === codeContainerTypes.input.key
                ? `${mls('Input')} ( ${selectedButtonName} )`
                : null}
            </div>

            <div className={styles.queryEditorArea} ref={codeContainerRef}>
              <div
                style={
                  codeContainerType === codeContainerTypes.code.key
                    ? { height: '100%' }
                    : { display: 'none', height: '100%' }
                }
              >
                <CodeEditor
                  height={codeContainerHeight}
                  defaultLanguage='javascript'
                  value={code}
                  onChange={handleCodeChange}
                />
              </div>
              <div
                style={
                  codeContainerType === codeContainerTypes.output.key
                    ? { height: '100%' }
                    : { display: 'none', height: '100%' }
                }
              >
                <CodeEditor
                  height={codeContainerHeight}
                  defaultLanguage='json'
                  value={JSON.stringify(outputData?.msg ?? '')}
                  onChange={() => {}}
                  autoFormatTriggerCharacters={codeContainerType}
                  readOnly={true}
                />
              </div>
              <div
                style={
                  codeContainerType === codeContainerTypes.input.key
                    ? { height: '100%' }
                    : { display: 'none', height: '100%' }
                }
              >
                <CodeEditor
                  height={codeContainerHeight}
                  defaultLanguage='json'
                  value={inputData}
                  onChange={(e) => {
                    setInputData(e);
                    setIsInputChanged(true);
                  }}
                  autoFormatTriggerCharacters={codeContainerType}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className={styles.queryOutputContainer}>
        <div className={styles.querytitleArea}>{mls('Input')}</div>
        <div className={styles.queryEditorArea}>
          <Editor
            height='100%'
            // height='10rem'
            defaultLanguage='json'
            value={inputData}
            onChange={(e) => {
              setInputData(e);
              setIsInputChanged(true);
            }}
            theme='vs-dark'
            // options={{ lineNumbers: false }}
          />
        </div>
      </div>
    </div>
  );
};

export default QueryContainer;
