import React from 'react';
import { Accordion } from 'react-bootstrap';

import SlideSwitch from 'lib/reusable-components/Components/SlideSwitch/SlideSwitch';
import styles from '../../ComponentSettingWrapper.module.scss';

import PopUpDivChild from 'lib/reusable-components/PopUpDivChild/PopUpDivChild';
import {
  handleMainTableTypeColumnSelection,
  handleColumnSelection,
  handleInnerTableColumnSelection,
} from './additionalSettingsUtils';
import { getFinalTableID } from '../../utils/componentSettingFunction';
interface selectedColumnPopupProps {
  isSelectedColumnPopup: boolean;
  handleIsSelectedColumnPopup: Function;
  relatedTable: any;
  selectedConnectedTable: any;
  relatedTableList: any;
  tableID: string;
  relationType: string;
  tableData: any;
  updateRelatedTable: Function;
  setRelatedTableList: Function;
  connectedTablesData: any;
}
const SelectedColumnPopup = ({
  isSelectedColumnPopup,
  handleIsSelectedColumnPopup,
  relatedTable,
  selectedConnectedTable,
  relatedTableList,
  tableID,
  relationType,
  tableData,
  updateRelatedTable,
  connectedTablesData,
}: selectedColumnPopupProps) => {
  const isMainTable = relatedTable?.relationType === 'MAIN';
  return (
    <div className={styles.tablesSelectionOuterContainer}>
      <PopUpDivChild
        initialValue={isSelectedColumnPopup}
        setPopUp={handleIsSelectedColumnPopup}
        popupTitle={`${tableData?.name ?? tableID} (${relationType})`}
      >
        <div className={styles.tablesSelectionContainer}>
          {React.Children.toArray(
            (tableData?.columnSchema ?? []).map((columnData: any) => {
              const isStartWithD3 = (columnData?.columnName ?? '').startsWith('__d3__');
              if (isStartWithD3) {
                return null;
              }
              if (
                relatedTable?.relationType === 'belongsTo' &&
                columnData?.dataType === 'belongsTo'
              ) {
                return null;
              }

              const isSelected = (relatedTable?.defaultView ?? []).some(
                (columnNameElem: any) =>
                  columnNameElem?.columnName === (columnData?.columnName ?? '')
              );

              return (
                <div
                  className={`${styles.columnSelectionDiv} `}
                  onClick={() =>
                    isMainTable
                      ? handleMainTableTypeColumnSelection({
                          relatedTable,
                          relatedTableList,
                          columnData,
                          connectedTablesData,
                          isSelected,
                          updateRelatedTable,
                        })
                      : handleColumnSelection({
                          relatedTable,
                          relatedTableList,
                          columnData,
                          selectedConnectedTable: selectedConnectedTable,
                          isSelected,
                          updateRelatedTable,
                        })
                  }
                >
                  <SlideSwitch isChecked={isSelected} setIsChecked={() => {}} />
                  {columnData?.name ?? columnData?.columnName} ({columnData?.dataType})
                </div>
              );
            })
          )}
        </div>
        <TableListRecursion
          relatedTable={relatedTable}
          selectedConnectedTable={selectedConnectedTable}
          relatedTableList={relatedTableList}
          connectedTablesData={connectedTablesData}
          updateRelatedTable={updateRelatedTable}
          nestedLevel={1}
        />
      </PopUpDivChild>
    </div>
  );
};

export default SelectedColumnPopup;

const TableListRecursion = ({
  relatedTable,
  selectedConnectedTable,
  relatedTableList,
  connectedTablesData,
  updateRelatedTable,
  nestedLevel,
}: any) => {
  return (
    <Accordion>
      {(relatedTable?.tableList || []).map((innerTableElem: any, innerTableIndex: number) => {
        const finalTableID = getFinalTableID({
          tableID: innerTableElem?.tableID,
          relationType: innerTableElem?.relationType,
          relationID: innerTableElem?.relationID,
        });
        const innerTableData = selectedConnectedTable?.innerBelongsToTable?.[finalTableID];
        if (!innerTableData) {
          return null;
        }
        const handleSelection = ({
          columnData,
          isSelected,
        }: {
          columnData: any;
          isSelected: boolean;
        }) => {
          const newTableList = handleInnerTableColumnSelection({
            innerTableElem,
            innerTableIndex,
            relatedTable,
            relatedTableList,
            columnData,
            connectedTablesData,
            isSelected,
            updateRelatedTable,
          });
          updateRelatedTable({
            ...relatedTable,
            tableList: newTableList,
          });
        };

        const updateInnerRelatedTable = (innerTableList: any) => {
          const reorderedTableListView = Array.from(relatedTable?.tableList || []);
          reorderedTableListView.splice(innerTableIndex, 1);
          reorderedTableListView.splice(innerTableIndex, 0, innerTableList);
          updateRelatedTable({
            ...relatedTable,
            tableList: reorderedTableListView,
          });
        };

        return (
          <Accordion.Item eventKey={innerTableElem.tableID} className='selectedInnerTableAccordion'>
            <Accordion.Header>
              {innerTableElem.screenName ?? innerTableElem.tableID}
            </Accordion.Header>
            <Accordion.Body>
              <div className={styles.tablesSelectionContainer}>
                {React.Children.toArray(
                  (innerTableData?.columnSchema ?? []).map((columnData: any) => {
                    const isStartWithD3 = (columnData?.columnName ?? '').startsWith('__d3__');
                    if (isStartWithD3) {
                      return null;
                    }
                    const isSelected = (innerTableElem?.defaultView ?? []).some(
                      (columnNameElem: any) =>
                        columnNameElem?.columnName === (columnData?.columnName ?? '')
                    );
                    return (
                      <div
                        className={`${styles.columnSelectionDiv} `}
                        onClick={() => handleSelection({ columnData, isSelected })}
                      >
                        <SlideSwitch isChecked={isSelected} setIsChecked={() => {}} />
                        {columnData?.name ?? columnData?.columnName} ({columnData?.dataType})
                      </div>
                    );
                  })
                )}
              </div>
              {nestedLevel > 0 ? (
                <TableListRecursion
                  relatedTable={innerTableElem}
                  selectedConnectedTable={innerTableData}
                  relatedTableList={relatedTable?.tableList ?? []}
                  connectedTablesData={connectedTablesData}
                  updateRelatedTable={updateInnerRelatedTable}
                  nestedLevel={nestedLevel - 1}
                />
              ) : null}
            </Accordion.Body>
          </Accordion.Item>
        );
      })}
    </Accordion>
  );
};
