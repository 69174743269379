import { mls } from 'lib/multilanguagesupport';
import PopUpDivChild from 'lib/reusable-components/PopUpDivChild/PopUpDivChild';
import React, { useEffect, useState } from 'react';
import styles from './aiCustomizationEditorContainer.module.scss';
import CheckBox from 'lib/reusable-components/Components/CheckBox/CheckBox';
import MoreActionContainerRight from './MoreActionContainerRight';
import SearchInputDiv from 'lib/reusable-components/Components/SearchInputDiv/SearchInputDiv';

interface moreActionsProps {
  isMoreActionPopup: boolean;
  setIsMoreActionPopup: Function;
  componentSchema: any;
  updateMoreActionFunction: Function;
  selectedData: any;
  selectedTable: string;
}

const MoreActions = ({
  isMoreActionPopup,
  setIsMoreActionPopup,
  componentSchema,
  updateMoreActionFunction,
  selectedData,
  selectedTable,
}: moreActionsProps) => {
  const moreAction = selectedData?.actionOrder ?? [];
  const [templatesData, setTemplatesData] = useState(componentSchema?.templates ?? {});
  const [templatesDataSearch, setTemplatesDataSearch] = useState('');

  useEffect(() => {
    const templateData = componentSchema?.templates ?? {};
    let newDownloadData = {};
    Object.keys(templateData ?? {}).forEach((templateKey: string) => {
      const selectedTemplateData = templateData[templateKey];
      if (selectedTemplateData?.origin !== selectedTable) {
        return;
      }
      if (
        (selectedTemplateData?.title ?? '')
          .toLowerCase()
          ?.includes(templatesDataSearch.toLowerCase())
      ) {
        newDownloadData = { ...newDownloadData, [templateKey]: selectedTemplateData };
      }
    });
    setTemplatesData(newDownloadData);
  }, [templatesDataSearch, componentSchema?.templates]);

  const updateMoreActionData = (e: any) => {
    const isRemove = e?.isRemove;
    const target = e?.target;
    if (isRemove) {
      const newMoreAction = (moreAction ?? []).filter((elem: any) => elem.id !== target?.id);
      updateMoreActionFunction(newMoreAction);
    } else {
      const newMoreAction = [
        ...moreAction,
        {
          id: target?.id,
        },
      ];
      updateMoreActionFunction(newMoreAction);
    }
  };

  return (
    <div>
      <PopUpDivChild
        initialValue={isMoreActionPopup}
        setPopUp={setIsMoreActionPopup}
        popupTitle={mls('More Actions')}
      >
        <div className={styles.moreActionContainer}>
          <div className={styles.moreActionContainerLeft}>
            <div className={styles.actionSelectionContainer}>
              <div className={styles.actionSelectionContainerTitle}>{mls('Templates')}</div>
              <div className={styles.actionSelectionContainerSubTitle}>
                <SearchInputDiv
                  value={templatesDataSearch}
                  onChange={setTemplatesDataSearch}
                  border
                />
              </div>
              <div className={styles.actionSelectionInnerContainer}>
                {Object.keys(templatesData ?? {}).map((templateKey: string) => {
                  const selectedTemplateData = templatesData[templateKey];
                  const templateData = {
                    id: templateKey,
                    type: selectedTemplateData?.type,
                    title: selectedTemplateData?.title,
                  };
                  const isSelected = (moreAction ?? []).some((moreActionElem: any) => {
                    return moreActionElem.id === templateData.id;
                  });
                  return (
                    <div
                      className={styles.actionSelectionInnerElem}
                      onClick={() => {
                        updateMoreActionData({ isRemove: isSelected, target: templateData });
                      }}
                    >
                      <CheckBox checked={isSelected} onClick={(action: any) => {}} />{' '}
                      {templateData.title}
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
          <MoreActionContainerRight
            moreAction={moreAction}
            setMoreAction={updateMoreActionFunction}
            componentSchema={componentSchema}
          />
        </div>
      </PopUpDivChild>
    </div>
  );
};

export default MoreActions;
