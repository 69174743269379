import React from 'react';
import Modal from '@mui/material/Modal';
import { buttonTypeObject } from '../../data/appComponentData';
import { mls } from 'lib/multilanguagesupport';
const ConfirmReset = ({
  confirmResetModal,
  setConfirmResetModal,
  handleReset,
  buttonType,
  isDelete = false,
}: {
  buttonType: string;
  confirmResetModal: boolean;
  isDelete?: boolean;
  setConfirmResetModal: Function;
  handleReset: Function;
}) => {
  const buttonName = buttonTypeObject?.[buttonType as keyof typeof buttonTypeObject]?.name;
  return (
    <Modal open={confirmResetModal} onClose={() => setConfirmResetModal(false)}>
      <div className='modal-dialog modal-dialog-centered mw-600px'>
        <div className='modal-content d-flex justify-content-center align-items-center p-5'>
          <p className='p-5 mb-7 fs-4 fw-bold'>
            {mls(
              isDelete
                ? `Are you sure you want to Delete this ${buttonName ?? 'Action Button'}`
                : 'Are you sure you want to Reset this Code'
            )}
            ?
          </p>
          <div className='d-flex'>
            <button
              data-bs-dismiss='modal'
              onClick={() => handleReset()}
              style={{ marginTop: -15 }}
              className='btn btn-sm mb-2 btn-danger btn-light-primary mx-4'
            >
              {mls(isDelete ? `Delete ${buttonName ?? 'Action Button'}` : 'Reset Code')}
            </button>
            <button
              onClick={() => setConfirmResetModal(false)}
              style={{ marginTop: -15 }}
              className='btn btn-sm mb-2 btn-primary btn-light-primary mx-4'
            >
              {mls('Cancel')}
            </button>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default ConfirmReset;
